import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class SaydouMatPaginatorIntl extends MatPaginatorIntl {
    itemsPerPageLabel = '每頁筆數：';
    nextPageLabel = '下一頁';
    previousPageLabel = '上一頁';
    firstPageLabel = '第一頁';
    lastPageLabel = '最後一頁';
    //      this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    // this.matPaginatorIntl.nextPageLabel = '下一頁';
    // this.matPaginatorIntl.previousPageLabel = '上一頁';
    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return `第 0 筆、共 ${length} 筆`;
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return `第 ${startIndex + 1} - ${endIndex} 筆  共 ${length} 筆`;
    };
}
