import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ContractService {
    baseUrl: string = environment.backend.baseURL;
    constructor(private http: HttpClient) { }
    apply(body) {
        return this.http.post<any>(`${this.baseUrl}/api/payflow/apply/newebpay`, body);
    }
    getContract(id) {
        return this.http.get<any>(`${this.baseUrl}/api/getContract/${id}`);
    }
    restoreContract(body) {
        return this.http.post<any>(`${this.baseUrl}/api/restoreContract`, body);
    }
    private ignoreLoadingHesders() {
        return { headers: { 'ignoreloading': '1' } };
    }
    downloadExportRecord(id) {
        return this.http.get<any>(`${this.baseUrl}/api/downloadOnlineContract/${id}`);
    };

    // downloadExportRecord(id) {
    //     return this.http.get<any>(`${this.baseUrl}/api/management/crm/downloadExportRecord/${id}`);
    // }
}
