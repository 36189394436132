import {  Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonService {
    private hostSource = new BehaviorSubject<any>(undefined);
    currentHost = this.hostSource.asObservable();
    constructor() {

    }

    public jsonParse(str) {
        try {
            return JSON.parse(str);
        }
        catch (e) {
            console.log(e);
            // Return a default object, or null based on use case.
            return {};
        }
    }

    public setHost(host: any) {
        this.hostSource.next(host);
    }
}
