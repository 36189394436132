<mat-toolbar style="background-color:#873e74; position: fixed; top:0; z-index: 501;">
    <mat-toolbar-row>
        <img src="../../../assets/images/saydou.png"
            style="width:35px; object-fit: cover; height: 35px; border-radius: 15%; margin-right:20px" />
        <span style="color:white">SayDou 神美</span>
        <span class="toolbar-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="login_panel {{theme}}" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md=" center"
    [ngStyle.lt-md]="{'margin-top' : '56px'}" *ngIf="baseDatas$ | async as baseDatas;">
    <div *ngIf="!exist else info" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md=" center">
        <!-- <img src="../../../../assets/images/empty_product.png" style="width:285px" /> -->
        <h1>您目前尚未串接藍新金流</h1>
        <button mat-raised-button color="primary" [routerLink]="['/newebpay/apply']">
            前往進件
        </button>
    </div>
    <ng-template #info>
        <h2 style="margin-top:50px">藍新金流帳號資訊</h2>
        <mat-card class="box" [ngStyle.lt-md]="{'width': '95%','margin-top': '11px'}" style="width:65%;">
            <mat-card-content [ngStyle.lt-md]="{'font-size': '14px'}" style="padding:20px 20px 0 20px">
                <mat-form-field appearance="outline" style="width:100%">
                    <mat-label>門店</mat-label>
                    <mat-select [formControl]="modifyForm.get('storid')" (selectionChange)="storeChange($event)">
                        <mat-option *ngFor="let store of storeData" [value]="store.storid">
                            {{store.stonam}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="summary-area" style="color:black" *ngIf="payStatus.length > 0">
                    <!-- <span class="money">{{getSalaryTotal()|number:'1.0'}}</span> -->
                    <div fxLayout="row" fxLayoutAlign="space-evenly start" fxLayoutGap="10px" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="space-evenly center">
                        <div *ngIf="merchantNumber != ''">
                            <h2 mat-line>商店代號</h2>
                            <div mat-line>{{merchantNumber}}</div>
                        </div>
                        <div *ngFor="let pay of payStatus">
                            <h2 mat-line>{{pay.name}}</h2>
                            <div mat-line>{{pay.enable}}</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px" fxLayout.xs="column">

                    </div>
                </div>
                <div class="summary-area" style="color:black;margin-top:15px"
                    *ngIf="memberStatus != null || merchantStatus != null">
                    <!-- <span class="money">{{getSalaryTotal()|number:'1.0'}}</span> -->
                    <div fxLayout="row" fxLayoutAlign="space-evenly start" fxLayoutGap="10px" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="space-evenly center">
                        <div *ngIf="memberStatus != null">
                            <h2 mat-line>會員狀態:{{memberStatus.status}}</h2>
                            <div mat-line>{{memberStatus.description}}</div>
                        </div>
                        <div *ngIf="merchantStatus != null">
                            <h2 mat-line>商店狀態:{{merchantStatus.status}}</h2>
                            <div mat-line>{{merchantStatus.description}}</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px" fxLayout.xs="column">
                    </div>
                </div>
                <mat-expansion-panel [expanded]="true" [hideToggle]="false" style="margin-top:20px"
                    *ngIf="payStatus.length > 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3 class="section-title" style="margin-top:10px">修改基本資訊</h3>
                        </mat-panel-title>
                        <mat-panel-description style="margin-top:10px">
                            請輸入需要修改的資料，如果不需修改，請留空白。聯繫人相關資料為必填
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="modifyForm" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="30px">
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>* 聯繫人姓名</mat-label>
                            <input matInput formControlName="ApplicantName">
                            <mat-hint>聯繫人姓名，若異動資訊有需要補件或其他聯繫時，藍新會聯繫此人</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.ApplicantName.hasError('required')">
                                <strong>請填寫聯繫人姓名</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>* 聯繫人 email</mat-label>
                            <input matInput formControlName="ApplicantEmail">
                            <mat-error *ngIf="modifyForm.controls.ApplicantEmail.hasError('required')">
                                <strong>請填寫聯繫人 email</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.ApplicantEmail.hasError('email')">
                                <strong>信箱不符合格式</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>* 聯繫人電話</mat-label>
                            <input matInput formControlName="ApplicantPhone">
                            <mat-hint>例：0x-000111或09xx-000111</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.ApplicantPhone.hasError('required')">
                                <strong>請填寫聯繫人電話</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.ApplicantPhone.hasError('pattern')">
                                <strong>請填寫正確格式，例：0x-000111或09xx-000111</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>會員姓名</mat-label>
                            <input matInput formControlName="MemberName">
                            <mat-hint *ngIf="newebpayData.memberType == 'business'">企業會員請填入於工商登記資料相同之資訊</mat-hint>
                            <mat-hint
                                *ngIf="newebpayData.memberType == 'personal'">個人會員請填入身分證登記姓名，若要修改需一併填寫身份證相關資訊</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p"
                            *ngIf="newebpayData.memberType == 'personal' && modifyForm.controls.MemberName.value">
                            <mat-label>* 身份證字號</mat-label>
                            <input matInput formControlName="MenberUnified">
                            <mat-error *ngIf="modifyForm.controls.MenberUnified.hasError('required')">
                                <strong>請填寫身份證字號</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.MenberUnified.hasError('idnum')">
                                <strong>身份證字號格式錯誤</strong>
                            </mat-error>
                        </mat-form-field>
                        <h4 class="w80p"
                            *ngIf="newebpayData.memberType == 'personal' && modifyForm.controls.MemberName.value">*
                            身份證發證日期</h4>
                        <div class="w80p" fxLayout="row wrap" fxLayoutAlign="space-between center"
                            fxLayout.lt-md="column"
                            *ngIf="newebpayData.memberType == 'personal' && modifyForm.controls.MemberName.value">
                            <mat-form-field appearance="legacy" style="width:30%; margin:0"
                                [ngStyle.lt-md]="{'width':'100%'}">
                                <mat-label>民國年</mat-label>
                                <mat-select formControlName="taiwanYear" (selectionChange)="getDateOption()">
                                    <mat-option *ngFor="let year of taiwanYearsOption" [value]="year">
                                        {{year}}年
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" style="width:30%; margin:0"
                                [ngStyle.lt-md]="{'width':'100%'}">
                                <mat-label>月份</mat-label>
                                <mat-select formControlName="taiwanMonth" (selectionChange)="getDateOption()">
                                    <mat-option [value]="0">1月</mat-option>
                                    <mat-option [value]="1">2月</mat-option>
                                    <mat-option [value]="2">3月</mat-option>
                                    <mat-option [value]="3">4月</mat-option>
                                    <mat-option [value]="4">5月</mat-option>
                                    <mat-option [value]="5">6月</mat-option>
                                    <mat-option [value]="6">7月</mat-option>
                                    <mat-option [value]="7">8月</mat-option>
                                    <mat-option [value]="8">9月</mat-option>
                                    <mat-option [value]="9">10月</mat-option>
                                    <mat-option [value]="10">11月</mat-option>
                                    <mat-option [value]="11">12月</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" style="width:30%; margin:0"
                                [ngStyle.lt-md]="{'width':'100%'}">
                                <mat-label>日期</mat-label>
                                <mat-select formControlName="taiwanDate">
                                    <mat-option *ngFor="let date of taiwanDatesOption" [value]="date">
                                        {{date}}號
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="legacy" class="w80p"
                            *ngIf="newebpayData.memberType == 'personal' && modifyForm.controls.MemberName.value">
                            <mat-label>* 身份證發證地點</mat-label>
                            <mat-select formControlName="IDCardPlace">
                                <mat-option *ngFor="let city of idcity" [value]="city.value">
                                    {{city.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p"
                            *ngIf="newebpayData.memberType == 'personal' && modifyForm.controls.MemberName.value">
                            <mat-label>* 身分證領補換</mat-label>
                            <mat-select formControlName="IDFrom">
                                <mat-option [value]="1">初發</mat-option>
                                <mat-option [value]="2">補證</mat-option>
                                <mat-option [value]="3">換發</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="legacy" class="w80p"
                            *ngIf="newebpayData.memberType == 'personal' && modifyForm.controls.MemberName.value">
                            <mat-label>* 出生年月日</mat-label>
                            <input matInput [matDatepicker]="pickerb" formControlName="Date">
                            <mat-datepicker-toggle matSuffix [for]="pickerb">
                                <mat-icon matDatepickerToggleIcon fontSet="saydou_icon" fontIcon="icn_calendar">
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerb>
                            </mat-datepicker>
                        </mat-form-field> -->
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>會員電話</mat-label>
                            <input matInput formControlName="MemberPhone">
                            <mat-hint>例：0x-000111或09xx-000111</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.MemberPhone.hasError('required')">
                                <strong>請填寫會員電話</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.MemberPhone.hasError('pattern')">
                                <strong>請填寫正確格式，例：0x-000111或09xx-000111</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>客服商店信箱</mat-label>
                            <input matInput formControlName="MerchantEmail">
                            <mat-hint>商店客服信箱</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.MerchantEmail.hasError('required')">
                                <strong>請填寫客服商店信箱</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.MerchantEmail.hasError('email')">
                                <strong>信箱不符合格式</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>商店城市</mat-label>
                            <mat-select formControlName="MerchantAddrCity" (selectionChange)="cityChange($event)">
                                <mat-option *ngFor="let key of objectKeys(zipcode)" [value]="key">
                                    {{key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p" *ngIf="area">
                            <mat-label>商店地區</mat-label>
                            <mat-select formControlName="MerchantAddrArea" (selectionChange)="areaChange($event)">
                                <mat-option *ngFor="let key of objectKeys(area)" [value]="key">
                                    {{key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>商店詳細地址</mat-label>
                            <textarea matInput formControlName="MerchantAddr" #twaddr></textarea>
                            <mat-hint align="end">{{twaddr.value.length}} / 60</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.MerchantAddr.hasError('required')">
                                <strong>請填寫詳細地址</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>商店英文地址</mat-label>
                            <textarea matInput formControlName="MerchantEnAddr" #enaddr maxlength="255"></textarea>
                            <mat-hint align="end">{{enaddr.value.length}} / 255</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.MerchantEnAddr.hasError('required')">
                                <strong>請填寫商店英文地址</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p" *ngIf="newebpayData.memberType == 'business'">
                            <mat-label>實收資本額</mat-label>
                            <input matInput formControlName="CapitalAmount">
                            <mat-error *ngIf="modifyForm.controls.CapitalAmount.hasError('required')">
                                <strong>請填寫實收資本額</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.CapitalAmount.hasError('min')">
                                <strong>實收資本額不得小於0</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p" *ngIf="newebpayData.memberType == 'business'">
                            <mat-label>核准設立日期</mat-label>
                            <input matInput [matDatepicker]="pickerC" formControlName="IncorporationDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerC">
                                <mat-icon matDatepickerToggleIcon fontSet="saydou_icon" fontIcon="icn_calendar">
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerC>
                            </mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p" *ngIf="newebpayData.memberType == 'business'">
                            <mat-label>公司登記地址</mat-label>
                            <input matInput formControlName="CompanyAddress">
                            <mat-hint></mat-hint>
                            <mat-error *ngIf="modifyForm.controls.CompanyAddress.hasError('required')">
                                <strong>請填寫地址</strong>
                            </mat-error>
                        </mat-form-field>
                        <!-- <h2>物流設定</h2>
                        <mat-form-field floatLabel=" always" appearance="none" class="w80p">
                            <mat-slide-toggle formControlName="Shipping" (change)="shippingEnable($event)">
                                <mat-label>啟用物流</mat-label>
                            </mat-slide-toggle>
                            <mat-hint>開啟物流可以使用四大超商取貨不付款(付款成功才出貨)服務</mat-hint>
                            <textarea matInput hidden></textarea>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>退貨取件人姓名</mat-label>
                            <input matInput formControlName="ShippingName">
                            <mat-hint>如有開啟物流設定，此為必填</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.MerchantEmail.hasError('required')">
                                <strong>請填寫退貨取件人姓名</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>退貨取件人行動電話</mat-label>
                            <input matInput formControlName="ShippingTel">
                            <mat-hint>如有開啟物流設定，此為必填</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.ShippingTel.hasError('required')">
                                <strong>請填寫退貨取件人行動電話</strong>
                            </mat-error>
                            <mat-error *ngIf="modifyForm.controls.ShippingTel.hasError('pattern')">
                                <strong>請填寫正確格式，例：09xx000111</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy" class="w80p">
                            <mat-label>退貨取件人電子信箱</mat-label>
                            <input matInput formControlName="ShippingEmail">
                            <mat-hint>如有開啟物流設定，此為必填</mat-hint>
                            <mat-error *ngIf="modifyForm.controls.ShippingEmail.hasError('required')">
                                <strong>請填寫退貨取件人電子信箱</strong>
                            </mat-error>
                        </mat-form-field> -->
                        <!-- <button type="saydou-button w80p" mat-raised-button style="margin:0 auto" color="primary"
                            (click)="saveConfirm()" [disabled]="!modifyForm.valid || !checkNotEmpty() || bindProcess">送出
                        </button> -->
                    </form>
                    <br>
                </mat-expansion-panel>
                <!-- <mat-expansion-panel [expanded]="true" [hideToggle]="false" style="margin-top:20px">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3 class="section-title" style="margin-top:10px">物流資訊</h3>
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <form [formGroup]="modifyForm" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="30px">

                    </form>
                </mat-expansion-panel> -->
            </mat-card-content>
            <mat-card-actions align="center" style="margin-bottom:12px" *ngIf="payStatus.length > 0">
                <button type="saydou-button" mat-raised-button color="primary" (click)="saveConfirm()"
                    [disabled]="!modifyForm.valid || !checkNotEmpty() || bindProcess">送出
                </button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</div>
