import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MediaChangeService } from 'src/app/services/common/media-change.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    model: any = {};
    authForm: FormGroup;
    returnUrl: string;
    code: string;
    account: string;
    baseUrl: string = environment.backend.baseURL;
    mobileMatches: boolean;
    theme = "petto-theme";
    primaryColor = "#AF4585";
    logoPngUrl = "../../../assets/images/saydou_logo.png";
    backgroundUrl = "../../../assets/images/login_background.jpg";
    disableBtn = false;
    constructor(private http: HttpClient,
        private _route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        private mc: MediaChangeService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private overlayContainer: OverlayContainer,
        private cs: CommonService,
        ) { }
    ngOnInit() {
        this.cs.currentHost.subscribe((host) => {
            this.theme = host.theme;
            this.primaryColor = host.primaryColor;
            this.logoPngUrl = host.logoPngUrl;
            this.backgroundUrl = host.backgroundUrl;
            document.documentElement.style.setProperty('--primary-color', this.primaryColor);
        });
        this.overlayContainer.getContainerElement().classList.add(this.theme);
        this.mc.matches.subscribe((matches: boolean) => {
            this.mobileMatches = matches;
        });
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this.code = this._route.snapshot.queryParams['c'] ?? '';
        this.account = this._route.snapshot.queryParams['a'] ?? '';
        if(this.code && this.account) {
            this.authForm = this.fb.group({
                cstcod: [this.code, Validators.required],
                usracc: [this.account, Validators.required],
                passwd: ['', Validators.required],
                captcha: [''],
            });
        } else if(!environment.production) {
            this.authForm = this.fb.group({
                cstcod: ['83575163', Validators.required],
                usracc: ['gordon', Validators.required],
                passwd: ['', Validators.required],
                captcha: [''],
            });
        } else {
            this.authForm = this.fb.group({
                cstcod: ['', Validators.required],
                usracc: ['', Validators.required],
                passwd: ['', Validators.required],
                captcha: [''],
            });
        }

    }
    signin() {
        let params: any = {};
        Object.keys(this.authForm.controls).forEach(key => {
            params[key] = this.authForm.controls[key].value;
        });
        this.recaptchaV3Service.execute('importantAction')
            .subscribe((token) => {
                params.captcha = token;
                this.http.post(`${this.baseUrl}/api/auth/login`, params, { headers: { 'ignorepermission': '1' } })
                    .subscribe((data: any) => {
                        if (data.status) {
                            localStorage.setItem('user', JSON.stringify(data.user));
                            localStorage.setItem('token', data.token);
                            this.router.navigate([this.returnUrl]);
                        } else {
                            this.authForm.controls.captcha.setValue('');
                            this.disableBtn = true;
                            if(data.redirect) {
                                setTimeout(() => {
                                    window.location.href = data.redirect + "/login?c=" + this.authForm.controls.cstcod.value + "&a=" + this.authForm.controls.usracc.value;
                                },3000);
                            }
                        }
                    });
            });
    }
}
