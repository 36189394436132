import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MediaChangeService } from 'src/app/services/common/media-change.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentflowService } from '../../../services/paymentflow.service';
import { SaydouSnackBarService } from 'src/app/services/common/saydou-snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ZIPCODE } from 'src/app/const/zipcode';
import { DeleteMessageConfig } from 'src/app/model/delete-message';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmDialog } from '../../common/delete-confirm/delete-confirm.component';
import * as moment from 'moment';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
    selector: 'app-newebpay-info',
    templateUrl: './newebpay.info.component.html',
    styleUrls: ['./newebpay.info.component.css']
})
export class NewebpayInfoComponent implements OnInit {
    idcity = [
        { value: '基市', name: '基隆市' },
        { value: '北市', name: '台北市' },
        { value: '新北市', name: '新北市' },
        { value: '北縣', name: '台北縣' },
        { value: '桃市', name: '桃園市' },
        { value: '桃縣', name: '桃園縣' },
        { value: '竹市', name: '新竹市' },
        { value: '竹縣', name: '新竹縣' },
        { value: '苗縣', name: '苗栗縣' },
        { value: '中市', name: '台中市' },
        { value: '中縣', name: '台中縣' },
        { value: '彰縣', name: '彰化縣' },
        { value: '投縣', name: '南投縣' },
        { value: '雲縣', name: '雲林縣' },
        { value: '嘉市', name: '嘉義市' },
        { value: '嘉縣', name: '嘉義縣' },
        { value: '南市', name: '台南市' },
        { value: '南縣', name: '台南縣' },
        { value: '高市', name: '高雄市' },
        { value: '高縣', name: '高雄縣' },
        { value: '屏縣', name: '屏東縣' },
        { value: '宜縣', name: '宜蘭縣' },
        { value: '花縣', name: '花蓮縣' },
        { value: '東縣', name: '台東縣' },
        { value: '連江', name: '連江縣' },
        { value: '金門', name: '金門縣' },
        { value: '澎縣', name: '澎湖縣' },
    ];
    taiwanYearsOption = [];
    taiwanDatesOption = [];
    taiwanYear = 0;
    taiwanMonth = 0;
    taiwanDate = 1;
    modifyForm: FormGroup;
    baseUrl: string = environment.backend.baseURL;
    goUrl: string = this.baseUrl;
    siteKey: string = environment.recaptchaKey;
    mobileMatches: boolean;
    errorStateMatcher: ErrorStateMatcher;
    errorMatcher = new MyErrorStateMatcher();
    baseDatas$: Observable<any>;
    applyStatus = false;
    exist = false;
    newebpayData = null;
    payStatus = [];
    memberStatus = null;
    merchantStatus = null;
    merchantNumber = '';
    storeData = [];
    bindProcess = false;
    objectKeys = Object.keys;
    area = false;
    zipcode = ZIPCODE;
    bankData = null;
    theme = "petto-theme";
    primaryColor = "#AF4585";
    constructor(private http: HttpClient,
        private fb: FormBuilder,
        private mc: MediaChangeService,
        private ps: PaymentflowService,
        private _snackBar: SaydouSnackBarService,
        private recaptchaV3Service: ReCaptchaV3Service,
        public dialog: MatDialog,
        private cs: CommonService,
        private overlayContainer: OverlayContainer,
    ) {
        let nowChineseYear = moment().year() - 1911;
        this.taiwanYear = nowChineseYear - 20;
        this.taiwanMonth = moment().month();
        for (let index = 0; index < 60; index++) {
            this.taiwanYearsOption.push(nowChineseYear - index);
        }
        this.cs.currentHost.subscribe((host) => {
            this.theme = host.theme;
            this.primaryColor = host.primaryColor;
            // this.logoPngUrl = host.logoPngUrl;
            // this.backgroundUrl = host.backgroundUrl;
            document.documentElement.style.setProperty('--primary-color', this.primaryColor);
        });
        this.overlayContainer.getContainerElement().classList.add(this.theme);
    }

    getDateOption() {
        this.taiwanDatesOption = [];
        const year = this.modifyForm.get('taiwanYear').value + 1911;
        const month = this.modifyForm.get('taiwanMonth').value;
        let d = moment().set('year', year).set('month', month).endOf('month');
        let monthDate = d.date();
        for (let index = 0; index < monthDate; index++) {
            this.taiwanDatesOption.push(index + 1);
        }
        this.modifyForm.get('taiwanDate').setValue(1);
    }

    ngOnInit() {
        this.mc.matches.subscribe((matches: boolean) => {
            this.mobileMatches = matches;
        });

        this.fetchBaseData();
    }

    fetchBaseData() {
        let requestArr: Observable<any>[] = [];
        // requestArr.push(this.http.get<any>(`${this.baseUrl}/api/management/taiwanBanks`));
        requestArr.push(this.ps.storeList());
        // requestArr.push(this.ps.checkPayStatus());
        this.baseDatas$ = forkJoin(requestArr).pipe(map(([storeData]) => {
            this.storeData = storeData.status ? storeData.list : [];
            this.storeData = this.storeData.filter(x => x.newebpay !== null);
            if (this.storeData.length > 0) {
                this.checkNewebpayStatus(this.storeData[0].storid);
                this.exist = true;
                this.newebpayData = this.storeData[0].newebpay;
                this.merchantNumber = this.newebpayData.MerchantID;
                this.modifyForm = this.fb.group({
                    CapitalAmount: [{ value: null, disabled: this.newebpayData.memberType != 'business' }, [Validators.min(0)]],
                    IncorporationDate: [{ value: null, disabled: this.newebpayData.memberType != 'business' }],
                    CompanyAddress: [{ value: '', disabled: this.newebpayData.memberType != 'business' }, [Validators.maxLength(100)]],
                    MemberName: [''],
                    MenberUnified: [{ value: '', disabled: true }],
                    IDCardPlace: [{ value: '', disabled: true }],
                    IDFrom: [{ value: '', disabled: true }],
                    // Date: [{ value: moment().subtract(18, 'years'), disabled: true}],
                    taiwanYear: [{ value: this.taiwanYear, disabled: true }],
                    taiwanMonth: [{ value: this.taiwanMonth, disabled: true }],
                    taiwanDate: [{ value: this.taiwanDate, disabled: true }],
                    MemberPhone: ['', [Validators.maxLength(13), Validators.pattern(/^\d{2,2}-\d{1,10}|^\d{4,4}-\d{1,8}$/)]],
                    MerchantEmail: ['', [Validators.email]],
                    MerchantAddrCity: ['', [Validators.maxLength(100)]],
                    MerchantAddrArea: ['', [Validators.maxLength(100)]],
                    MerchantAddrCode: ['', [Validators.maxLength(100)]],
                    MerchantAddr: ['', [Validators.maxLength(60)]],
                    MerchantEnAddr: ['', [Validators.maxLength(255)]],
                    ApplicantName: ['', [Validators.required, Validators.maxLength(50)]],
                    ApplicantEmail: ['', [Validators.required, Validators.maxLength(40), Validators.email]],
                    ApplicantPhone: ['', [Validators.required, Validators.maxLength(13), Validators.pattern(/^\d{2,2}-\d{1,10}|^\d{4,4}-\d{1,8}$/)]],
                    Shipping: [false, [Validators.required]],
                    ShippingName: [{ value: '', disabled: true }],
                    ShippingTel: [{ value: '', disabled: true }],
                    ShippingEmail: [{ value: '', disabled: true }],
                    storid: [this.storeData[0].storid, Validators.required],
                });
                if (!environment.production) {
                    this.modifyForm.get('ApplicantName').setValue("Gordon");
                    this.modifyForm.get('ApplicantEmail').setValue("gordon@aleip.com");
                    this.modifyForm.get('ApplicantPhone').setValue("0911-999666");
                }
                if (this.newebpayData.memberType == 'personal') {
                    this.modifyForm.get('MemberName').setValidators([Validators.maxLength(20)]);
                } else {
                    this.modifyForm.get('MemberName').setValidators([Validators.maxLength(60)]);
                }
                this.modifyForm.get('ApplicantName').setValue(this.newebpayData.ApplicantName);
                this.modifyForm.get('ApplicantEmail').setValue(this.newebpayData.ApplicantEmail);
                this.modifyForm.get('ApplicantPhone').setValue(this.newebpayData.ApplicantPhone);

                this.modifyForm.get('Shipping').setValue(this.newebpayData.Shipping == 1);
                this.modifyForm.get('ShippingName').setValue(this.newebpayData.ShippingName);
                this.modifyForm.get('ShippingTel').setValue(this.newebpayData.ShippingTel);
                this.modifyForm.get('ShippingEmail').setValue(this.newebpayData.ShippingEmail);
                this.shippingEnable({ checked: this.newebpayData.Shipping == 1 });
                this.getDateOption();
                this.modifyForm.get('MemberName').valueChanges.subscribe((value) => {
                    if (this.newebpayData.memberType == 'personal' && value) {
                        this.modifyForm.get('MenberUnified').enable();
                        this.modifyForm.get('IDCardPlace').enable();
                        this.modifyForm.get('IDFrom').enable();
                        // this.modifyForm.get('Date').enable();
                        this.modifyForm.get('taiwanYear').enable();
                        this.modifyForm.get('taiwanMonth').enable();
                        this.modifyForm.get('taiwanDate').enable();
                        this.modifyForm.get('MenberUnified').setValidators([Validators.required, this.verifyId]);
                        this.modifyForm.get('IDCardPlace').setValidators([Validators.required]);
                        this.modifyForm.get('IDFrom').setValidators([Validators.required]);
                        // this.modifyForm.get('Date').setValidators([Validators.required]);
                        this.modifyForm.get('taiwanYear').setValidators([Validators.required]);
                        this.modifyForm.get('taiwanMonth').setValidators([Validators.required]);
                        this.modifyForm.get('taiwanDate').setValidators([Validators.required]);
                    } else {
                        this.modifyForm.get('MenberUnified').disable();
                        this.modifyForm.get('IDCardPlace').disable();
                        this.modifyForm.get('IDFrom').disable();
                        // this.modifyForm.get('Date').disable();
                        this.modifyForm.get('taiwanYear').disable();
                        this.modifyForm.get('taiwanMonth').disable();
                        this.modifyForm.get('taiwanDate').disable();
                        this.modifyForm.get('MenberUnified').clearValidators();
                        this.modifyForm.get('IDCardPlace').clearValidators();
                        this.modifyForm.get('IDFrom').clearValidators();
                        // this.modifyForm.get('Date').clearValidators();
                        this.modifyForm.get('taiwanYear').clearValidators();
                        this.modifyForm.get('taiwanMonth').clearValidators();
                        this.modifyForm.get('taiwanDate').clearValidators();
                    }
                    this.modifyForm.get('MenberUnified').updateValueAndValidity();
                    this.modifyForm.get('IDCardPlace').updateValueAndValidity();
                    this.modifyForm.get('IDFrom').updateValueAndValidity();
                    // this.modifyForm.get('Date').updateValueAndValidity();
                    this.modifyForm.get('taiwanYear').updateValueAndValidity();
                    this.modifyForm.get('taiwanMonth').updateValueAndValidity();
                    this.modifyForm.get('taiwanDate').updateValueAndValidity();
                    this.modifyForm.updateValueAndValidity();
                });
            }
            return true;
        }
        ));
    }

    verifyId(control: FormControl) {
        let id = control.value;
        id = id.trim();
        let verification = id.match(/^[A-Z][12]\d{8}$/);
        if (verification === null) {
            return {
                idnum: {
                    error: true
                }
            };
        }
        let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
        let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]
        id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

        let checkSum = 0
        for (let i = 0; i < id.length; i++) {
            let c = parseInt(id[i])
            let w = weights[i]
            checkSum += c * w
        }
        if (checkSum % 10 == 0) {
            return null;
        } else {
            return {
                idnum: {
                    error: true
                }
            };
        }
    }

    storeChange(e) {
        const store = this.storeData.find(x => x.storid == e.value);
        this.newebpayData = store.newebpay
        this.merchantNumber = this.newebpayData.MerchantID;
        this.modifyForm.get('MemberName').clearValidators();
        this.getDateOption();
        if (store.newebpay.memberType == 'business') {
            this.modifyForm.get('CapitalAmount').enable();
            this.modifyForm.get('IncorporationDate').enable();
            this.modifyForm.get('CompanyAddress').enable();
            this.modifyForm.get('MemberName').setValidators([Validators.maxLength(60)]);
        } else {
            this.modifyForm.get('CapitalAmount').disable();
            this.modifyForm.get('IncorporationDate').disable();
            this.modifyForm.get('CompanyAddress').disable();
            this.modifyForm.get('MemberName').setValidators([Validators.maxLength(20)]);
        }
        this.modifyForm.get('MemberName').updateValueAndValidity();
        this.modifyForm.get('MemberName').setValue('');
        this.modifyForm.get('ApplicantName').setValue(this.newebpayData.ApplicantName);
        this.modifyForm.get('ApplicantEmail').setValue(this.newebpayData.ApplicantEmail);
        this.modifyForm.get('ApplicantPhone').setValue(this.newebpayData.ApplicantPhone);
        this.modifyForm.get('Shipping').setValue(this.newebpayData.Shipping == 1);
        this.modifyForm.get('ShippingName').setValue(this.newebpayData.ShippingName);
        this.modifyForm.get('ShippingTel').setValue(this.newebpayData.ShippingTel);
        this.modifyForm.get('ShippingEmail').setValue(this.newebpayData.ShippingEmail);
        this.shippingEnable({ checked: this.newebpayData.Shipping == 1 });
        this.checkNewebpayStatus(e.value);
        if (!environment.production) {
            this.modifyForm.get('MenberUnified').setValue("A122106477");
            this.modifyForm.get('IDCardPlace').setValue('桃市');
            this.modifyForm.get('IDFrom').setValue(1);
        }
    }

    shippingEnable($event: any) {
        if ($event.checked) {
            this.modifyForm.get('ShippingName').setValidators([Validators.required, Validators.maxLength(50)]);
            this.modifyForm.get('ShippingTel').setValidators([Validators.required, Validators.maxLength(13), Validators.pattern(/^09[0-9]{8}$/)]);
            this.modifyForm.get('ShippingEmail').setValidators([Validators.required, Validators.email, Validators.maxLength(40)]);
            this.modifyForm.get('ShippingName').enable();
            this.modifyForm.get('ShippingTel').enable();
            this.modifyForm.get('ShippingEmail').enable();
            if (!environment.production) {
                this.modifyForm.get('ShippingName').setValue("Gordon");
                this.modifyForm.get('ShippingEmail').setValue("gordon@aleip.com");
                this.modifyForm.get('ShippingTel').setValue("0911999666");
            }
            this.modifyForm.get('ShippingName').setValue(this.newebpayData.ShippingName);
            this.modifyForm.get('ShippingTel').setValue(this.newebpayData.ShippingTel);
            this.modifyForm.get('ShippingEmail').setValue(this.newebpayData.ShippingEmail);
        } else {
            this.modifyForm.get('ShippingName').clearValidators();
            this.modifyForm.get('ShippingTel').clearValidators();
            this.modifyForm.get('ShippingEmail').clearValidators();
            this.modifyForm.get('ShippingName').disable();
            this.modifyForm.get('ShippingTel').disable();
            this.modifyForm.get('ShippingEmail').disable();
        }
        this.modifyForm.get('ShippingName').updateValueAndValidity();
        this.modifyForm.get('ShippingTel').updateValueAndValidity();
        this.modifyForm.get('ShippingEmail').updateValueAndValidity();
        this.modifyForm.updateValueAndValidity();
    }

    checkNewebpayStatus(storid) {
        this.ps.checkPayStatus(storid).subscribe(res => {
            if (res.status) {
                this.payStatus = res.data;
                if (res.member !== undefined) {
                    this.memberStatus = res.member;
                }
                if (res.merchant !== undefined) {
                    this.merchantStatus = res.merchant;
                }
            }
        });
    }

    gogogo() {
        const controls = this.modifyForm.controls;
        let invalid1 = [];
        for (const name in controls) {
            controls[name].markAsTouched();
            if (controls[name].invalid) {
                invalid1.push(name);
            }
        }
        if (invalid1.length > 0) {
            this._snackBar.showBar("請檢查資料是否填寫正確", '', 'warning');
            return;
        }
        this.bindProcess = true;
        let value = this.modifyForm.value;
        if (value.IncorporationDate) {
            value.IncorporationDate = this.modifyForm.get("IncorporationDate").value.format("YYYY-MM-DD");
        }
        if(this.newebpayData.memberType == 'personal' && value.MemberName){
            const idCardDate = moment().set('year', value.taiwanYear + 1911).set('month', value.taiwanMonth).set('date', value.taiwanDate);
            value.IDCardDate = idCardDate.format("YYYY-MM-DD");
            // if (value.Date) {
            //     value.Date = this.modifyForm.get("Date").value.format("YYYYMMDD");
            // }
        }
        this.recaptchaV3Service.execute('importantAction')
            .subscribe((token) => {
                value.token = token;
                this._snackBar.showBar("修改中，請稍候…", '', 'info');
                setTimeout(() => {
                    this.bindProcess = false;
                }, 30000);
                this.ps.modify(value).subscribe(res => {
                    this.bindProcess = false;
                    if (res.status) {
                        this._snackBar.showBar('修改成功');
                        this.modifyForm.reset();
                        this.modifyForm.get('taiwanYear').setValue(this.taiwanYear);
                        this.modifyForm.get('taiwanMonth').setValue(this.taiwanMonth);
                        this.modifyForm.get('taiwanDate').setValue(this.taiwanDate);
                        this.modifyForm.get('storid').setValue(value.storid);
                        this.checkNewebpayStatus(value.storid);
                    }
                });
            });
    }

    cityChange(e) {
        this.area = this.zipcode[e.value];
    }

    areaChange(e) {
        if (this.area[e.value]) {
            this.modifyForm.get('MerchantAddrCode').setValue(this.area[e.value]);
        }
    }

    checkNotEmpty() {
        let value = this.modifyForm.value;
        let hasValue = false;
        for (const key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                const element = value[key];
                if (element !== "" && element !== null) {
                    hasValue = true;
                }
            }
        }
        return hasValue;
    }

    saveConfirm() {
        let config: DeleteMessageConfig = {
            title: `修改確認`,
            // subTitle: `下次預約`,
            confirmMessage: `確認要修改資料？`,
            confirmText: '確認',
            cancelText: '否',
            countdown: 3
        };
        const dialogRef = this.dialog.open(DeleteConfirmDialog, {
            data: config,
            minWidth: '30%',
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.gogogo();
            }
        });
    }
}

class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        let invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        if (control.parent.errors == null) {
            invalidParent = false;
        }
        return (invalidCtrl || invalidParent);
    }
}
