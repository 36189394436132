<mat-toolbar color="primary" class="app-header">
    <mat-toolbar-row>
        <img src="../../../assets/images/saydou_toolbar_logo.png" style="width:200px"/>
        <button mat-button routerLink="/management">
            管理處
        </button>
        <span class="toolbar-spacer"></span>
        <!-- <mat-icon class="toolbar-icon" aria-hidden="false" aria-label="Example user verified icon">home</mat-icon> -->

        <button mat-icon-button (click)="logout()" matTooltip="登出">
            <mat-icon>exit_to_app</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
<div class="app-container">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="25px" style="flex-wrap: wrap">
        <mat-card *ngFor="let store of userInfo.store" fxFlex="0 0 calc(33%-25px)" fxFlex.md="0 0 calc(50%-25px)"
            fxFlex.sm="0 0 100%" fxFlex.xs="0 0 100%">
            <mat-card-header>
                <!-- <div mat-card-avatar class="example-header-image"></div> -->
                <mat-card-title>{{store.stonam}}</mat-card-title>
                <!-- <mat-card-subtitle>{{member.subtitle}}</mat-card-subtitle> -->
            </mat-card-header>
            <img mat-card-image [ngStyle.xs]="{'min-height.px':200}" src="https://bellissimo.cc//DoorStor/image/{{store.stoimg}}" alt="Photo of {{store.stonam}}">
            <mat-card-content>
                <p>
                    {{store.stodes}}
                </p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="primary" (click)="enterBranch(store.storid)">進入</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
