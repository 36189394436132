import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MediaChangeService } from 'src/app/services/common/media-change.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PaymentflowService } from '../../../services/paymentflow.service';
import { SaydouSnackBarService } from 'src/app/services/common/saydou-snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as moment from 'moment';
import { ZIPCODE } from 'src/app/const/zipcode';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ManagementService } from 'src/app/services/management.service';
import { CommonService } from 'src/app/services/common/common.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
    selector: 'app-newebpay-apply',
    templateUrl: './newebpay.apply.component.html',
    styleUrls: ['./newebpay.apply.component.css']
})
export class NewebpayApplyComponent implements OnInit {
    model: any = {};
    agreeForm: FormGroup;
    typeForm: FormGroup;
    baseForm: FormGroup;
    merchantForm: FormGroup;
    returnUrl: string;
    baseUrl: string = environment.backend.baseURL;
    goUrl: string = this.baseUrl;
    siteKey: string = environment.recaptchaKey;
    mobileMatches: boolean;
    errorStateMatcher: ErrorStateMatcher;
    errorMatcher = new MyErrorStateMatcher();
    baseDatas$: Observable<any>;
    industryData = [];
    industrySelections: number[];
    state = '';
    applyStatus = false;
    exist = false;
    creditcard = false;
    account = 'no';
    error = "";
    bindProcess = false;
    objectKeys = Object.keys;
    area = false;
    zipcode = ZIPCODE;
    storeData = [];
    appliedStoreData = [];
    images = [
        { path: '../../../../assets/images/marketplace/marketplace_1.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_2.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_3.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_4.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_5.jpg' },
    ];
    idcity = [
        { value: '基市', name: '基隆市' },
        { value: '北市', name: '台北市' },
        { value: '新北市', name: '新北市' },
        { value: '北縣', name: '台北縣' },
        { value: '桃市', name: '桃園市' },
        { value: '桃縣', name: '桃園縣' },
        { value: '竹市', name: '新竹市' },
        { value: '竹縣', name: '新竹縣' },
        { value: '苗縣', name: '苗栗縣' },
        { value: '中市', name: '台中市' },
        { value: '中縣', name: '台中縣' },
        { value: '彰縣', name: '彰化縣' },
        { value: '投縣', name: '南投縣' },
        { value: '雲縣', name: '雲林縣' },
        { value: '嘉市', name: '嘉義市' },
        { value: '嘉縣', name: '嘉義縣' },
        { value: '南市', name: '台南市' },
        { value: '南縣', name: '台南縣' },
        { value: '高市', name: '高雄市' },
        { value: '高縣', name: '高雄縣' },
        { value: '屏縣', name: '屏東縣' },
        { value: '宜縣', name: '宜蘭縣' },
        { value: '花縣', name: '花蓮縣' },
        { value: '東縣', name: '台東縣' },
        { value: '連江', name: '連江縣' },
        { value: '金門', name: '金門縣' },
        { value: '澎縣', name: '澎湖縣' },
    ];
    businessType = [
        { value: '4225', name: '倉儲服務 ' },
        { value: '4722', name: '旅行社' },
        { value: '4812', name: '電話通訊設備及服務' },
        { value: '4899', name: '有線電視' },
        { value: '5045', name: '3C 商品' },
        { value: '5094', name: '寶石/黃金/珠寶貴重物' },
        { value: '5192', name: '書報雜誌' },
        { value: '5261', name: '園藝用品' },
        { value: '5399', name: '一般商品買賣' },
        { value: '5422', name: '冷凍食品' },
        { value: '5462', name: '西點麵包' },
        { value: '5499', name: '食品名特產' },
        { value: '5699', name: '服飾配件' },
        { value: '5732', name: '電器行' },
        { value: '5812', name: '餐廳' },
        { value: '5941', name: '運動商品' },
        { value: '5946', name: '攝影用品' },
        { value: '5963', name: '直銷' },
        { value: '5977', name: '化妝/美容保養產品' },
        { value: '5992', name: '花店' },
        { value: '5995', name: '寵物用品' },
        { value: '7011', name: '飯店/民宿' },
        { value: '7261', name: '喪葬服務及用品' },
        { value: '7298', name: '美容美體服務' },
        { value: '7311', name: '廣告服務' },
        { value: '7372', name: '網路資訊服務' },
        { value: '7392', name: '諮詢服務' },
        { value: '7519', name: '休閒交通工具租借' },
        { value: '7996', name: '樂區 / 博覽會' },
        { value: '7999', name: '娛樂休閒服務' },
        { value: '8220', name: '學校' },
        { value: '8299', name: '補習/教學服務' },
        { value: '8398', name: '社會福利團體' },
        { value: '8651', name: '政治團體' },
        { value: '8661', name: '宗教團體' },
        { value: '8999', name: '其他專業服務' },
    ];
    encity = [
        { value: 'Keelung City', name: 'Keelung City 基隆市' },
        { value: 'Taipei City', name: 'Taipei City 台北市' },
        { value: 'New Taipei City', name: 'New Taipei City 新北市' },
        { value: 'Taoyuan City', name: 'Taoyuan City 桃園市' },
        { value: 'Hsinchu City', name: 'Hsinchu City 新竹市' },
        { value: 'Hsinchu Country', name: 'Hsinchu Country 新竹縣' },
        { value: 'Miaoli Country', name: 'Miaoli Country 苗栗縣' },
        { value: 'Taichung City', name: 'Taichung City 台中市' },
        { value: 'Changhua Coutry', name: 'Changhua Coutry 彰化縣' },
        { value: 'Nantou Country', name: 'Nantou Country 南投縣' },
        { value: 'Yunlin Country', name: 'Yunlin Country 雲林縣' },
        { value: 'Chiayi Country', name: 'Chiayi Country 嘉義縣' },
        { value: 'Chiayi City', name: 'Chiayi City 嘉義市' },
        { value: 'Tainan City', name: 'Tainan City 台南市' },
        { value: 'Kaohsiung City', name: 'Kaohsiung City 高雄市' },
        { value: 'Pingtung Country', name: 'Pingtung Country 屏東縣' },
        { value: 'Yilan Country', name: 'Yilan Country 宜蘭縣' },
        { value: 'Hualien Country', name: 'Hualien Country 花蓮縣' },
        { value: 'Taitung Country', name: 'Taitung Country 台東縣' },
        { value: 'Penghu Country', name: 'Penghu Country 澎湖縣' },
        { value: 'Kinmen Country', name: 'Kinmen Country 金門縣' },
        { value: 'Lienchiang Country', name: 'Lienchiang Country 連江縣' },

    ];

    taiwanYearsOption = [];
    taiwanDatesOption = [];
    taiwanYear = 0;
    taiwanMonth = 0;
    taiwanDate = 1;
    filteredOptions: Observable<any[]>;
    filteredOptionsBranch: Observable<any[]>;
    bankData: any[] = [];
    branchData: any[] = [];
    bankBranchData: any[] = [];
    theme = "petto-theme";
    primaryColor = "#AF4585";
    constructor(private http: HttpClient,
        private fb: FormBuilder,
        private mc: MediaChangeService,
        private ps: PaymentflowService,
        public ms: ManagementService,
        private _snackBar: SaydouSnackBarService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private overlayContainer: OverlayContainer,
        private cs: CommonService,

    ) {
        let nowChineseYear = moment().year() - 1911;
        this.taiwanYear = nowChineseYear - 20;
        this.taiwanMonth = moment().month();
        for (let index = 0; index < 60; index++) {
            this.taiwanYearsOption.push(nowChineseYear - index);
        }
        this.cs.currentHost.subscribe((host) => {
            this.theme = host.theme;
            this.primaryColor = host.primaryColor;
            // this.logoPngUrl = host.logoPngUrl;
            // this.backgroundUrl = host.backgroundUrl;
            document.documentElement.style.setProperty('--primary-color', this.primaryColor);
        });
        this.overlayContainer.getContainerElement().classList.add(this.theme);
    }

    getDateOption() {
        this.taiwanDatesOption = [];
        const year = this.baseForm.get('taiwanYear').value + 1911;
        const month = this.baseForm.get('taiwanMonth').value;
        let d = moment().set('year', year).set('month', month).endOf('month');
        let monthDate = d.date();
        for (let index = 0; index < monthDate; index++) {
            this.taiwanDatesOption.push(index + 1);
        }
        this.baseForm.get('taiwanDate').setValue(1);
    }

    ngOnInit() {
        this.mc.matches.subscribe((matches: boolean) => {
            this.mobileMatches = matches;
        });
        this.typeForm = this.fb.group({
            memberType: ['personal', Validators.required],
            storid: [[], Validators.required]
        });
        this.agreeForm = this.fb.group({
            contract_shop_agree: [false, Validators.requiredTrue]
        });
        this.baseForm = this.fb.group({
            idcardnum: [{ value: '', disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required, this.verifyId]],
            taxnum: [{ value: '', disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.required, this.taxnumValidator]],
            RepresentName: [{ value: '', disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.required]],
            ManagerID: [{ value: '', disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.required, this.verifyId]],
            IDCardDate: [{ value: moment().subtract(20, 'years').startOf('month'), disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required]],
            IDCardPlace: [{ value: '', disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required]],
            // IDPic: [{ value: 1, disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required]],
            IDFrom: [{ value: 1, disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required]],
            Date: [{ value: moment().subtract(18, 'years'), disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required]],
            CapitalAmount: [{ value: 0, disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.min(0), Validators.required]],
            IncorporationDate: [{ value: moment().subtract(3, 'years'), disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.required]],
            CompanyAddress: [{ value: '', disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.required, Validators.maxLength(100)]],
            MemberNameBusiness: [{ value: '', disabled: this.typeForm.get('memberType').value != 'business' }, [Validators.required, Validators.maxLength(60)]],
            MemberNamePersonal: [{ value: '', disabled: this.typeForm.get('memberType').value != 'personal' }, [Validators.required, Validators.maxLength(20)]],
            MemberPhone: ['', [Validators.required, Validators.maxLength(13), Validators.pattern(/^\d{2,2}-\d{1,10}|^\d{4,4}-\d{1,8}$/)]],
            MemberAddress: ['', [Validators.required, Validators.maxLength(100)]],
            taiwanYear: [this.taiwanYear, Validators.required],
            taiwanMonth: [this.taiwanMonth, Validators.required],
            taiwanDate: [this.taiwanDate, Validators.required],
        });
        this.merchantForm = this.fb.group({
            ManagerName: ['', [Validators.required, Validators.maxLength(20)]],
            ManagerNameE: ['', [Validators.required, Validators.maxLength(20)]],
            LoginAccount: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20), Validators.pattern(/^[\w_@\.]+/)]],
            ManagerMobile: ['', [Validators.required, Validators.pattern(/^09[0-9]{8}$/)]],
            ManagerEmail: ['', [Validators.required, Validators.email]],
            DisputeMail: ['', [Validators.required, Validators.email]],
            MerchantEmail: ['', [Validators.required, Validators.email]],
            MerchantName: ['', [Validators.required, Validators.maxLength(20)]],
            MerchantNameE: ['', [Validators.required, Validators.maxLength(100)]],
            BusinessType: ['8999', [Validators.required]],
            MerchantWebURL: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)]],
            MerchantAddrCity: ['', [Validators.required, Validators.maxLength(100)]],
            MerchantAddrArea: ['', [Validators.required, Validators.maxLength(100)]],
            MerchantAddrCode: ['', [Validators.required, Validators.maxLength(100)]],
            MerchantAddr: ['', [Validators.required, Validators.maxLength(60)]],
            MerchantEnAddr: ['', [Validators.required, Validators.maxLength(255)]],
            CityE: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^[a-zA-Z ]+/)]],
            MerchantDesc: ['', [Validators.required, Validators.maxLength(255)]],
            BankCode: ['', [Validators.required, Validators.maxLength(3)]],
            SubBankCode: ['', [Validators.required, Validators.maxLength(4)]],
            BankAccount: ['', [Validators.required, Validators.maxLength(14), Validators.pattern(/^[\d]+/)]],
            AccountName: ['', [Validators.required, Validators.maxLength(80)]],
            WithdrawSetting: [3, [Validators.required, Validators.min(0), Validators.max(6)]]
        });

        if (!environment.production) {
            this.baseUrl = "";
            this.goUrl = "https://45ca21d1b558.ngrok.io";
            this.baseForm.get('idcardnum').setValue('A122106477');
            this.baseForm.get('taxnum').setValue('71721492');
            this.baseForm.get('RepresentName').setValue('張安');
            this.baseForm.get('ManagerID').setValue('A122106477');
            this.baseForm.get('IDCardPlace').setValue('桃市');
            this.baseForm.get('CompanyAddress').setValue('桃園市');
            this.baseForm.get('MemberNameBusiness').setValue('安騰');
            this.baseForm.get('MemberNamePersonal').setValue('張安');
            this.baseForm.get('MemberPhone').setValue('0955-666222');
            this.baseForm.get('MemberAddress').setValue('桃園市');

            this.merchantForm.get('ManagerName').setValue('張安');
            this.merchantForm.get('ManagerNameE').setValue('Anteng');
            this.merchantForm.get('LoginAccount').setValue('sakkadmin');
            this.merchantForm.get('ManagerMobile').setValue('0944556661');
            this.merchantForm.get('ManagerEmail').setValue('gordon@aleip.com');
            this.merchantForm.get('DisputeMail').setValue('gordon@aleip.com');
            this.merchantForm.get('MerchantEmail').setValue('gordon@aleip.com');
            this.merchantForm.get('MerchantName').setValue('神美');
            this.merchantForm.get('MerchantNameE').setValue('SayDou');
            this.merchantForm.get('BusinessType').setValue('8999');
            this.merchantForm.get('MerchantWebURL').setValue('https://www.saydou.com');
            this.merchantForm.get('MerchantAddrCity').setValue('桃園市');
            this.merchantForm.get('MerchantAddrArea').setValue('龜山區');
            this.merchantForm.get('MerchantAddrCode').setValue('333');
            this.merchantForm.get('MerchantAddr').setValue('文興路146號');
            this.merchantForm.get('MerchantEnAddr').setValue('4F.-2, No. 146, Wenxing Rd., Guishan Dist., Taoyuan City');
            this.merchantForm.get('CityE').setValue('Taoyuan City');
            this.merchantForm.get('MerchantDesc').setValue('讓美麗是個好生意');
            this.merchantForm.get('BankCode').setValue('822');
            this.merchantForm.get('SubBankCode').setValue('3142');
            this.merchantForm.get('BankAccount').setValue('12345612345612');
            this.merchantForm.get('AccountName').setValue('張安');
        }
        this.getDateOption();
        this.fetchBaseData();
        this.filteredOptions = this.merchantForm.get('BankCode').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.code),
                map(name => name ? this._filter(name) : this.bankData.slice())
            );
        this.filteredOptionsBranch = this.merchantForm.get('SubBankCode').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.code),
                map(name => name ? this._filterBranch(name) : this.bankBranchData.slice())
            );
    }

    private _filter(name: string): any[] {
        const filterValue = name.toLowerCase();
        return this.bankData.filter(bank => bank.code.toLowerCase().indexOf(filterValue) === 0 || bank.name.toLowerCase().indexOf(filterValue) === 0);
    }
    private _filterBranch(name: string): any[] {
        // const filterValue = name.toLowerCase();
        // return this.bankBranchData.filter(
        //     bank => bank.code.toLowerCase().indexOf(filterValue) === 0
        //         || bank.branch.toLowerCase().indexOf(filterValue) >= 0
        //         || bank.name.toLowerCase().indexOf(filterValue) === 0
        // );
        const filterValue = name.toLowerCase();
        return this.bankBranchData.filter(
            bank => bank.branch_code.toLowerCase().indexOf(filterValue) >= 0
                || bank.name.toLowerCase().indexOf(filterValue) === 0
        );
    }
    displayFn(bank: any): string {
        return bank && bank.code ? bank.code + " " + bank.name : '';
    }
    displayFnBranch(branch: any): string {
        return branch && branch.branch_code ? branch.bank_code + branch.branch_code + " " + branch.name : '';
    }
    bankSelected(e: MatAutocompleteSelectedEvent) {
        if (e.option.value.code) {
            this.bankBranchData = this.branchData.filter(x => x.bank_code == e.option.value.code);
            this.merchantForm.get('SubBankCode').setValue("");
        }
    }

    fetchBaseData() {
        let requestArr: Observable<any>[] = [];
        // requestArr.push(this.http.get<any>(`${this.baseUrl}/api/management/taiwanBanks`));
        requestArr.push(this.ms.taiwanBanks());
        // requestArr.push(this.ps.checkExist());
        requestArr.push(this.ps.storeList());
        this.baseDatas$ = forkJoin(requestArr).pipe(map(([bankData, storeData]) => {
            // this.bankData = bankData.status ? bankData.data : [];
            this.storeData = storeData.status ? storeData.list : [];
            this.appliedStoreData = this.storeData.filter(x => x.newebpay !== null);
            if (bankData.status) {
                // this.bankData = bankData.data;
                // this.branchData = bankData.branch;
                for (const key in bankData.data) {
                    const element = bankData.data[key];
                    element.code = key;
                    this.bankData.push(element);
                    this.branchData = this.branchData.concat(element.branchs);
                }
            }
            const notyet = this.storeData.filter(x => x.newebpay == null);
            if (notyet.length == 0) {
                this.exist = true;
                // this.creditcard = check.enableCredit;
            }
            // if (tempData.status) {
            //     if (tempData.data.id !== undefined) {
            //         this.baseForm.get('id').setValue(tempData.data.id);
            //         this.baseForm.get('contact').setValue(tempData.data.contact);
            //         this.baseForm.get('mobile').setValue(tempData.data.mobile);
            //         this.baseForm.get('email').setValue(tempData.data.email);
            //         this.baseForm.get('brand_name').setValue(tempData.data.brand_name);
            //         this.baseForm.get('address').setValue(tempData.data.address);
            //         this.baseForm.get('account').setValue(tempData.data.account);
            //         tempData.data.industry = tempData.data.industry.map(x => parseInt(x));
            //         this.baseForm.get('industry').setValue(tempData.data.industry);
            //     }
            // }
            return true;
        }
        ));
    }

    gogogo() {
        const controls = this.baseForm.controls;
        let invalid1 = [];
        let invalid2 = [];
        for (const name in controls) {
            controls[name].markAsTouched();
            if (controls[name].invalid) {
                invalid1.push(name);
            }
        }
        const c2 = this.merchantForm.controls;
        for (const name in c2) {
            c2[name].markAsTouched();
            if (c2[name].invalid) {
                invalid2.push(name);
            }
        }
        if (invalid1.length > 0 || invalid2.length > 0) {
            this._snackBar.showBar("請檢查資料是否填寫正確", '', 'warning');
            return;
        }

        this.bindProcess = true;
        let v1 = this.typeForm.value;
        let v2 = this.baseForm.value;
        let v3 = this.merchantForm.value;
        let v4 = this.agreeForm.value;
        let value = { ...v1, ...v2, ...v3, ...v4 };
        if (value.BankCode.code == undefined) {
            this._snackBar.showBar('請選擇金融機構', '關閉', 'warning');
            this.bindProcess = false;
            return;
        }
        if (value.SubBankCode.branch_code == undefined) {
            this._snackBar.showBar('請選擇金融機構分行代碼', '關閉', 'warning');
            this.bindProcess = false;
            return;
        }
        value.BankCode = value.BankCode.code;
        // value.SubBankCode = value.SubBankCode.branch.length == 7 ? value.SubBankCode.branch.slice(-4) : value.SubBankCode.branch;
        value.SubBankCode = value.SubBankCode.branch_code;
        if (value.IDCardDate) {
            value.IDCardDate = value.IDCardDate.set('year', v2.taiwanYear + 1911).set('month', v2.taiwanMonth).set('date', v2.taiwanDate);
            value.IDCardDate = this.baseForm.get("IDCardDate").value.format("YYYY-MM-DD");
        }
        if (value.Date) {
            value.Date = this.baseForm.get("Date").value.format("YYYYMMDD");
        }
        if (value.IncorporationDate) {
            value.IncorporationDate = this.baseForm.get("IncorporationDate").value.format("YYYY-MM-DD");
        }
        this.recaptchaV3Service.execute('importantAction')
            .subscribe((token) => {
                value.token = token;
                this._snackBar.showBar("申請中，請稍候…", '', 'info');
                setTimeout(() => {
                    this.bindProcess = false;
                }, 30000);
                this.ps.apply(value).subscribe(res => {
                    this.bindProcess = false;
                    if (res.status) {
                        this._snackBar.showBar('申請成功');
                        this.applyStatus = true;
                        this.baseForm.reset();
                        this.merchantForm.reset();
                    }
                });
            });
    }

    cityChange(e) {
        this.area = this.zipcode[e.value];
    }

    areaChange(e) {
        if (this.area[e.value]) {
            this.merchantForm.get('MerchantAddrCode').setValue(this.area[e.value]);
        }
    }

    taxnumValidator(control: FormControl) {
        let taxnum = control.value;
        if (taxnum == '') {
            return null;
        }
        var invalidList = "00000000,11111111";
        if (/^\d{8}$/.test(taxnum) == false || invalidList.indexOf(taxnum) != -1) {
            return {
                taxnum: {
                    error: true
                }
            };
        }
        var validateOperator = [1, 2, 1, 2, 1, 2, 4, 1],
            sum = 0,
            calculate = function (product) { // 個位數 + 十位數
                var ones = product % 10,
                    tens = (product - ones) / 10;
                return ones + tens;
            };
        for (var i = 0; i < validateOperator.length; i++) {
            sum += calculate(taxnum[i] * validateOperator[i]);
        }
        if (sum % 5 == 0 || (taxnum[6] == "7" && (sum + 1) % 5 == 0)) {
            return null;
        } else {
            return {
                taxnum: {
                    error: true
                }
            };
        }
    }

    verifyId(control: FormControl) {
        let id = control.value;
        id = id.trim();
        let verification = id.match(/^[A-Z][12]\d{8}$/);
        if (verification === null) {
            return {
                idnum: {
                    error: true
                }
            };
        }
        let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
        let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]
        id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

        let checkSum = 0
        for (let i = 0; i < id.length; i++) {
            let c = parseInt(id[i])
            let w = weights[i]
            checkSum += c * w
        }
        if (checkSum % 10 == 0) {
            return null;
        } else {
            return {
                idnum: {
                    error: true
                }
            };
        }
    }

    memberTypeChange() {
        if (this.typeForm.get("memberType").value == 'personal') {
            this.baseForm.get('idcardnum').enable();
            // this.baseForm.get('IDCardDate').enable();
            // this.baseForm.get('IDCardPlace').enable();
            // this.baseForm.get('IDFrom').enable();
            this.baseForm.get('MemberNamePersonal').enable();
            this.baseForm.get('taxnum').disable();
            this.baseForm.get('RepresentName').disable();
            this.baseForm.get('ManagerID').disable();
            this.baseForm.get('CapitalAmount').disable();
            this.baseForm.get('IncorporationDate').disable();
            this.baseForm.get('CompanyAddress').disable();
            this.baseForm.get('MemberNameBusiness').disable();
        } else {
            this.baseForm.get('idcardnum').disable();
            // this.baseForm.get('IDCardDate').disable();
            // this.baseForm.get('IDCardPlace').disable();
            // this.baseForm.get('IDFrom').disable();
            this.baseForm.get('MemberNamePersonal').disable();
            this.baseForm.get('taxnum').enable();
            this.baseForm.get('RepresentName').enable();
            this.baseForm.get('ManagerID').enable();
            this.baseForm.get('CapitalAmount').enable();
            this.baseForm.get('IncorporationDate').enable();
            this.baseForm.get('CompanyAddress').enable();
            this.baseForm.get('MemberNameBusiness').enable();
        }
    }

    test() {
        const controls = this.baseForm.controls;
        let invalid1 = [];
        let invalid2 = [];
        for (const name in controls) {
            controls[name].markAsTouched();
            if (controls[name].invalid) {
                invalid1.push(name);
            }
        }
        const c2 = this.merchantForm.controls;
        for (const name in c2) {
            c2[name].markAsTouched();
            if (c2[name].invalid) {
                invalid2.push(name);
            }
        }
        console.log(invalid1);
        console.log(invalid2);
    }
}

@Component({
    selector: 'shop-contract',
    templateUrl: './shop-contract.html',
    styleUrls: ['./shop-contract.component.css']

})
export class ShopContract {
    constructor() {

    }

    ngAfterViewInit() {

    }
}

class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        let invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        if (control.parent.errors == null) {
            invalidParent = false;
        }
        return (invalidCtrl || invalidParent);
    }
}
