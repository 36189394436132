<div class="login_panel {{theme}}" fxLayout="row" fxLayoutAlign="center center"
    [ngStyle]="{'background-image':'url(' + backgroundUrl + ')'}">
    <mat-card class="box">
        <img *ngIf="!mobileMatches" mat-card-image src="{{logoPngUrl}}" style="width:350px;"
            [ngStyle]="{'margin': theme == 'saydou-theme' ? '0 auto' : '70px auto'}">
        <!-- <form class="example-form" (ngSubmit)="loginForm.form.valid && signin()" #loginForm="ngForm"> -->
        <mat-card-content>
            <form class="example-form" (ngSubmit)="signin()" #loginForm="ngForm" fxLayout="column"
                fxLayoutAlign="center center" [formGroup]="authForm">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="客戶代碼" name="cstcod" formControlName="cstcod" required>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="帳號" name="usracc" formControlName="usracc" required>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="密碼" type="password" name="passwd" formControlName="passwd" required>
                </mat-form-field>
                <button mat-raised-button color="primary" class="btn-block" style="margin-top: 15px;"
                    [disabled]="authForm.invalid || disableBtn">登入</button>
            </form>
            <!--  -->
        </mat-card-content>
    </mat-card>
</div>
