<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
    <h3 [innerHTML]="data.subTitle"></h3>
    <p *ngIf="data.description" [innerHTML]="data.description"></p>
    <!-- <p>{{data.confirmMessage ? data.confirmMessage : "確定要刪除嗎？"}}</p> -->
    <p [innerHTML]="data.confirmMessage ? data.confirmMessage : '確定要刪除嗎？'"></p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close
        style="border-radius: 16px; border-color:var(--primari-color)">{{data.cancelText ? data.cancelText :
        "取消"}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
        style="border-radius: 16px;" [disabled]="countDown > 0">
        {{data.confirmText ? data.confirmText : "刪除"}}
        <span *ngIf="countDown > 0">({{countDown}})</span>
    </button>
</mat-dialog-actions>
