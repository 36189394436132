import { Component, OnInit } from '@angular/core';
import { Helpers } from 'src/app/helpers';
@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {
    normalLogin = Helpers.checkLogin();
    groupLogin = Helpers.checkGroupLogin();
  constructor() { }

  ngOnInit(): void {
  }

}
