import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Helpers } from "../helpers";
import { SaydouSnackBarService } from '../services/common/saydou-snackbar.service';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private _snackBar: SaydouSnackBarService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("url: " + state.url + " canActivate");
        const portal = state.url.split('/')[1];
        if(portal == 'management'){
            if (Helpers.checkLogin()) {
                const notPayCount = Helpers.getNotPayCount();
                const contractMsg = Helpers.checkServiceTime();
                if (Helpers.checkPermission(state.url) || state.url == '/404' || state.url == '/denied') {
                    if (state.url != '/404' && state.url != '/denied') {
                        if (contractMsg.type == 'over') { //合約到期
                            this._snackBar.showBar("您的合約已到期(" + contractMsg.time + ")，帳號將於到期14天之後停用，請儘速聯繫您的管理師，以維護您的權益，謝謝! 如您已完成續約，請重新登入", '', 'error');

                        } else if (contractMsg.type == 'aboutto') { //合約到期前一個月
                            this._snackBar.showBar("您的合約即將於" + contractMsg.time + "到期，請儘速聯繫您的管理師，以維護您的權益，謝謝! 如您已完成續約，請重新登", '', 'warning');
                        }
                        else {
                            // if (notPayCount > 0) {
                            //     this._snackBar.showBar("您的主合約帳單已逾期兩個月尚未繳納，請儘速聯繫您的管理師，以維護您的權益，謝謝! 如您已完成付款，請重新登入或聯繫您的管理師", '', 'error');
                            // }
                        }
                    }
                    return true;
                } else {
                    if (state.url.indexOf('/management/checkout') >= 0 && Helpers.getCheckoutPermission()) {
                        if (contractMsg.type == 'over') { //合約到期
                            this._snackBar.showBar("您的合約已到期，帳號將於到期14天之後停用，請儘速聯繫您的管理師，以維護您的權益，謝謝! 如您已完成續約，請重新登入", '', 'error');

                        } else if (contractMsg.type == 'aboutto') { //合約到期前一個月
                            this._snackBar.showBar("您的合約即將於" + contractMsg.time + "到期，請儘速聯繫您的管理師，以維護您的權益，謝謝! 如您已完成續約，請重新登入", '', 'warning');
                        }
                        else {
                            // if (notPayCount > 0) {
                            //     this._snackBar.showBar("您的主合約帳單已逾期兩個月尚未繳納，請儘速聯繫您的管理師，以維護您的權益，謝謝! 如您已完成付款，請重新登入或聯繫您的管理師", '', 'error');
                            // }
                        }
                        return true;
                    }
                    var link = Helpers.getDefaultFunction(state.url);
                    if (link != "") {
                        this.router.navigate([link]);
                    } else {
                        this.router.navigate(["/denied"]);
                    }
                    return false;
                }
            } else {
                this.router.navigate(["/login"]);
                return false;
            }
        }
        if(portal == 'group'){
            if (Helpers.checkGroupLogin()) {
                if (Helpers.checkGroupPermission(state.url) || state.url == '/404' || state.url == '/denied') {
                    return true;
                } else {
                    var link = Helpers.getGroupDefaultFunction(state.url);
                    if (link != "") {
                        this.router.navigate([link]);
                    } else {
                        this.router.navigate(["/denied"]);
                    }
                    return false;
                }
            } else {
                this.router.navigate(["/group/login"]);
                return false;
            }
        }
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("url: " + state.url + " canActivateChild");
        if (Helpers.checkLogin()) {
            if (Helpers.checkPermission(state.url) || state.url == '/404' || state.url == '/denied') {
                return true;
            } else {
                if (state.url.indexOf('/management/checkout') >= 0 && Helpers.getCheckoutPermission()) {
                    return true;
                }
                var link = Helpers.getDefaultFunction(state.url);
                console.log(link);
                if (link != "") {
                    this.router.navigate([link]);
                } else {
                    this.router.navigate(["/denied"]);
                }
                return false;
            }
        } else {
            this.router.navigate(["/login"]);
            return false;
        }
    }
}
