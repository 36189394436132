import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
    imports: [
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            primaryColour: "#CCABDA",
            secondaryColour: "#AA90B5",
            backdropBackgroundColour: 'rgba(255, 255, 255, 0.1)'
        })
    ],
    exports: [
        NgxLoadingModule
    ],
    providers: []
})

export class LoadingModule { }
