import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import { Helpers } from "../helpers";
import { SaydouSnackBarService } from '../services/common/saydou-snackbar.service';

@Injectable()
export class RedirectGuard implements CanActivate {
    constructor(private router: Router,
        private _snackBar: SaydouSnackBarService,
        ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        let can = false;
		if (Helpers.checkLogin() && Helpers.isSuperAdmin()) {
            can = true;
        }
        if(!can) {
            this._snackBar.showBar('請先登入經營者帳號，再進行合約簽署', "關閉", 'error');
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return can;
    }
}
