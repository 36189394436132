import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteMessageConfig } from '../../../model/delete-message';

@Component({
    selector: 'app-delete-confirm',
    templateUrl: './delete-confirm.component.html',
    styleUrls: ['./delete-confirm.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DeleteConfirmDialog implements OnInit {
    countDown = 1;
    interval = null;
    constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteMessageConfig) {
        if(this.data.countdown >= 0) {
            this.countDown = this.data.countdown;
        }
    }

    ngOnInit(): void {
        if (this.countDown > 0) {
            this.interval = setInterval(() => {
                this.countDown--;
                if(this.countDown == 0) {
                    clearInterval(this.interval);
                }
            }, 1000);
        }
    }

}
