import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckoutBuyComponent } from '../components/management/checkout/buy/buy.component';
@Injectable()
export class CheckoutDeactiveGuard implements CanDeactivate<CheckoutBuyComponent> {

    constructor() { }
    canDeactivate(component: CheckoutBuyComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        console.log(component.ignore);
        if(component.ignore) {
            return true;
        } else {
            return confirm('確定離開？開單資料將不會保留');
        }
    }
}
