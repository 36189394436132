<mat-toolbar style="background-color:#873e74; position: fixed; top:0; z-index: 501;">
    <mat-toolbar-row>
        <img src="../../../assets/images/saydou.png"
            style="width:35px; object-fit: cover; height: 35px; border-radius: 15%; margin-right:20px" />
        <span style="color:white">SayDou 神美</span>
        <span class="toolbar-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="login_panel {{theme}}" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md=" center"
    [ngStyle.lt-md]="{'margin-top' : '56px'}">
    <ng-template [ngIf]="applyStatus === false && exist === false">
        <mat-card class="box" [ngStyle.lt-md]="{'width': '95%','margin-top': '11px'}" style="width:65%;"
            *ngIf="baseDatas$ | async as baseDatas;">
            <!-- <img *ngIf="!mobileMatches" mat-card-image src="../../../assets/images/saydou_logo.png"
                        style="width:350px; margin:0 auto"> -->
            <!-- <div class="panel-image" [ngStyle.lt-md]="{'width': '170px'}" style="width:200px">
                    </div> -->
            <mat-card-content [ngStyle.lt-md]="{'font-size': '14px'}">
                <!-- <carousel [margin]="0" [height]="mobileMatches ? 300 : 500" [arrows]="mobileMatches ? false : true"
                    [dots]="mobileMatches ? false : true" [objectFit]="mobileMatches ? 'contain' : 'cover'"
                    [cellWidth]="'100%'" [arrowsOutside]="false" [autoplay]="true" [dots]="true" [loop]="true"
                    [images]="images" [autoplayInterval]="5000" style="z-index:500">
                </carousel> -->
                <mat-horizontal-stepper linear #stepper>
                    <mat-step [stepControl]="agreeForm" [editable]="true">
                        <shop-contract></shop-contract>
                        <form [formGroup]="agreeForm">
                            <ng-template matStepLabel>集客小舖條款</ng-template>
                            <br>
                            <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="15px">
                                <mat-checkbox style="margin:0 auto" formControlName="contract_shop_agree">
                                    我同意
                                </mat-checkbox>
                            </div>
                            <div style="text-align: right;">
                                <button mat-raised-button matStepperNext color="primary"
                                    [disabled]="!agreeForm.valid">下一步</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="typeForm" [editable]="true">
                        <form [formGroup]="typeForm">
                            <ng-template matStepLabel>選擇申請身分</ng-template>
                            <div>
                                <h4>申請注意事項</h4>
                                <p>金流服務由藍新金流提供</p>
                                <ol>
                                    <li>因應各家銀行信用卡帳單格式，您的商店名稱會以不同方式展現。</li>
                                    <li>商店名稱請勿使用非您所擁有的品牌、企業名稱，以免觸法。</li>
                                    <ng-template [ngIf]="storeData.length > 1">
                                        <li>每家門店可以串接不同藍新金流帳號。</li>
                                        <li>若本次申請選擇多家門店，則所選擇的門店將會串接在同個藍新金流帳號</li>
                                    </ng-template>
                                </ol>
                            </div>
                            <mat-form-field appearance="standard" style="width:300px">
                                <mat-label>門店</mat-label>
                                <mat-select formControlName="storid" multiple>
                                    <mat-option *ngFor="let store of storeData" [value]="store.storid"
                                        [disabled]="store.newebpay !== null">
                                        {{store.stonam}} {{store.newebpay !== null ? '(已申請)' : ''}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <a mat-stroke-button color="primary" [routerLink]="['/newebpay/info']"
                                *ngIf="appliedStoreData.length > 0" target="_blank" style="margin-left:10px;">
                                查看已申請門店的狀態
                            </a>
                            <mat-radio-group fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
                                fxLayoutGap="20px" formControlName="memberType">
                                <div class="option-filter"
                                    (click)="typeForm.get('memberType').setValue('personal'); memberTypeChange()">
                                    <mat-radio-button value="personal" class="radio-button"
                                        (change)="memberTypeChange()">個人會員</mat-radio-button>
                                    <p class="option-des">不需統編，每個月交易額度30萬</p>
                                </div>
                                <div class="option-filter"
                                    (click)="typeForm.get('memberType').setValue('business'); memberTypeChange()">
                                    <mat-radio-button value="business" class="radio-button"
                                        (change)="memberTypeChange()">企業會員</mat-radio-button>
                                    <p class="option-des">需要統編，每個月交易額度100萬</p>
                                </div>
                            </mat-radio-group>
                            <br>
                            <div style="text-align: right;">
                                <button mat-raised-button matStepperPrevious color="primary"
                                    style="margin-right:12px">上一步</button>
                                <button mat-raised-button matStepperNext color="primary"
                                    [disabled]="!typeForm.valid || !agreeForm.valid">下一步</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="baseForm" [editable]="true">
                        <form [formGroup]="baseForm">
                            <ng-template matStepLabel>填寫基本資料</ng-template>
                            <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="30px">

                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 統一編號</mat-label>
                                    <input matInput formControlName="taxnum">
                                    <mat-error *ngIf="baseForm.controls.taxnum.hasError('required')">
                                        <strong>請填寫統一編號</strong>
                                    </mat-error>
                                    <mat-error *ngIf="baseForm.controls.taxnum.hasError('taxnum')">
                                        <strong>統一編號格式錯誤</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 企業代表人中文姓名</mat-label>
                                    <input matInput formControlName="RepresentName">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="baseForm.controls.RepresentName.hasError('required')">
                                        <strong>請填寫企業代表人中文姓名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 企業代表人身分證字號</mat-label>
                                    <input matInput formControlName="ManagerID">
                                    <mat-error *ngIf="baseForm.controls.ManagerID.hasError('required')">
                                        <strong>請填寫企業代表人身分證字號</strong>
                                    </mat-error>
                                    <mat-error *ngIf="baseForm.controls.ManagerID.hasError('idnum')">
                                        <strong>身份證字號格式錯誤</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'personal'">
                                    <mat-label>* 身份證字號</mat-label>
                                    <input matInput formControlName="idcardnum">
                                    <mat-error *ngIf="baseForm.controls.idcardnum.hasError('required')">
                                        <strong>請填寫身份證字號</strong>
                                    </mat-error>
                                    <mat-error *ngIf="baseForm.controls.idcardnum.hasError('idnum')">
                                        <strong>身份證字號格式錯誤</strong>
                                    </mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="legacy" class="w80p"
                                                                    *ngIf="typeForm.get('memberType').value == 'personal'">
                                                                    <mat-label>* 身份證發證日期</mat-label>
                                                                    <input matInput [matDatepicker]="pickerR" formControlName="IDCardDate">
                                                                    <mat-datepicker-toggle matSuffix [for]="pickerR">
                                                                        <mat-icon matDatepickerToggleIcon fontSet="saydou_icon" fontIcon="icn_calendar">
                                                                        </mat-icon>
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #pickerR>
                                                                    </mat-datepicker>
                                                                </mat-form-field> -->
                                <h4 class="w80p">* 身份證發證日期</h4>
                                <div class="w80p" fxLayout="row wrap" fxLayoutAlign="space-between center"
                                    fxLayout.lt-md="column">
                                    <mat-form-field appearance="legacy" style="width:30%; margin:0"
                                        [ngStyle.lt-md]="{'width':'100%'}">
                                        <mat-label>民國年</mat-label>
                                        <mat-select formControlName="taiwanYear" (selectionChange)="getDateOption()">
                                            <mat-option *ngFor="let year of taiwanYearsOption" [value]="year">
                                                {{year}}年
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="legacy" style="width:30%; margin:0"
                                        [ngStyle.lt-md]="{'width':'100%'}">
                                        <mat-label>月份</mat-label>
                                        <mat-select formControlName="taiwanMonth" (selectionChange)="getDateOption()">
                                            <mat-option [value]="0">1月</mat-option>
                                            <mat-option [value]="1">2月</mat-option>
                                            <mat-option [value]="2">3月</mat-option>
                                            <mat-option [value]="3">4月</mat-option>
                                            <mat-option [value]="4">5月</mat-option>
                                            <mat-option [value]="5">6月</mat-option>
                                            <mat-option [value]="6">7月</mat-option>
                                            <mat-option [value]="7">8月</mat-option>
                                            <mat-option [value]="8">9月</mat-option>
                                            <mat-option [value]="9">10月</mat-option>
                                            <mat-option [value]="10">11月</mat-option>
                                            <mat-option [value]="11">12月</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="legacy" style="width:30%; margin:0"
                                        [ngStyle.lt-md]="{'width':'100%'}">
                                        <mat-label>日期</mat-label>
                                        <mat-select formControlName="taiwanDate">
                                            <mat-option *ngFor="let date of taiwanDatesOption" [value]="date">
                                                {{date}}號
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 身份證發證地點</mat-label>
                                    <mat-select formControlName="IDCardPlace">
                                        <mat-option *ngFor="let city of idcity" [value]="city.value">
                                            {{city.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="legacy" class="w80p"
                                                                    *ngIf="typeForm.get('memberType').value == 'personal'">
                                                                    <mat-label>* 身分證照片</mat-label>
                                                                    <mat-select formControlName="IDPic">
                                                                        <mat-option [value]="1">有照片</mat-option>
                                                                        <mat-option [value]="0">無照片</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field> -->
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 身分證領補換</mat-label>
                                    <mat-select formControlName="IDFrom">
                                        <mat-option [value]="1">初發</mat-option>
                                        <mat-option [value]="2">補證</mat-option>
                                        <mat-option [value]="3">換發</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 出生年月日</mat-label>
                                    <input matInput [matDatepicker]="pickerb" formControlName="Date">
                                    <mat-datepicker-toggle matSuffix [for]="pickerb">
                                        <mat-icon matDatepickerToggleIcon fontSet="saydou_icon" fontIcon="icn_calendar">
                                        </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerb>
                                    </mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 實收資本額</mat-label>
                                    <input matInput formControlName="CapitalAmount">
                                    <mat-error *ngIf="baseForm.controls.CapitalAmount.hasError('required')">
                                        <strong>請填寫實收資本額</strong>
                                    </mat-error>
                                    <mat-error *ngIf="baseForm.controls.CapitalAmount.hasError('min')">
                                        <strong>實收資本額不得小於0</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 核准設立日期</mat-label>
                                    <input matInput [matDatepicker]="pickerC" formControlName="IncorporationDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerC">
                                        <mat-icon matDatepickerToggleIcon fontSet="saydou_icon" fontIcon="icn_calendar">
                                        </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerC>
                                    </mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 公司登記地址</mat-label>
                                    <input matInput formControlName="CompanyAddress">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="baseForm.controls.CompanyAddress.hasError('required')">
                                        <strong>請填寫地址</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'personal'">
                                    <mat-label>* 會員名稱</mat-label>
                                    <input matInput formControlName="MemberNamePersonal">
                                    <mat-hint>個人會員請提供身分證登記姓名</mat-hint>
                                    <mat-error *ngIf="baseForm.controls.MemberNamePersonal.hasError('required')">
                                        <strong>請填寫身分證登記姓名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p"
                                    *ngIf="typeForm.get('memberType').value == 'business'">
                                    <mat-label>* 會員名稱</mat-label>
                                    <input matInput formControlName="MemberNameBusiness">
                                    <mat-hint>企業會員請提供公司登記之名稱</mat-hint>
                                    <mat-error *ngIf="baseForm.controls.MemberNameBusiness.hasError('required')">
                                        <strong>請填寫公司登記之名稱</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 會員電話</mat-label>
                                    <input matInput formControlName="MemberPhone">
                                    <mat-hint>例：0x-000111或09xx-000111</mat-hint>
                                    <mat-error *ngIf="baseForm.controls.MemberPhone.hasError('required')">
                                        <strong>請填寫會員電話</strong>
                                    </mat-error>
                                    <mat-error *ngIf="baseForm.controls.MemberPhone.hasError('pattern')">
                                        <strong>請填寫正確格式，例：0x-000111或09xx-000111</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 會員聯絡地址</mat-label>
                                    <input matInput formControlName="MemberAddress">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="baseForm.controls.MemberAddress.hasError('required')">
                                        <strong>請填寫會員聯絡地址</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </form>
                        <div style="text-align: right;">
                            <!-- <button mat-raised-button color="primary" (click)="test()">test</button> -->
                            <button mat-raised-button matStepperPrevious color="primary"
                                style="margin-right:12px">上一步</button>
                            <button mat-raised-button matStepperNext color="primary"
                                [disabled]="!typeForm.valid || !baseForm.valid || !agreeForm.valid">下一步</button>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="merchantForm" [editable]="true">
                        <form [formGroup]="merchantForm" style="margin-bottom:20px">
                            <ng-template matStepLabel>填寫商家資料</ng-template>
                            <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="40px">
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 管理者中文姓名</mat-label>
                                    <input matInput formControlName="ManagerName">
                                    <mat-hint>合作商店的企業代表人與管理者中文姓名</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.ManagerName.hasError('required')">
                                        <strong>請填寫管理者中文姓名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 管理者英文姓名</mat-label>
                                    <input matInput formControlName="ManagerNameE">
                                    <mat-hint>合作商店的企業代表人與管理者英文姓名，格式為名,姓。例:Xiao ming,Wang</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.ManagerNameE.hasError('required')">
                                        <strong>請填寫管理者英文姓名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 管理者帳號</mat-label>
                                    <input matInput formControlName="LoginAccount">
                                    <mat-hint>藍新金流平台的管理者帳號。英數混合，可使用"_" "." "@"三種符號，5~20個字</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.LoginAccount.hasError('required')">
                                        <strong>請填寫管理者帳號</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 管理者行動電話</mat-label>
                                    <input matInput formControlName="ManagerMobile">
                                    <mat-hint>此欄位在多項服務提供時將進行驗證，請正確填寫</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.ManagerMobile.hasError('required')">
                                        <strong>請填寫管理者帳號</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 管理者 E-mail</mat-label>
                                    <input matInput formControlName="ManagerEmail">
                                    <mat-error *ngIf="merchantForm.controls.ManagerEmail.hasError('required')">
                                        <strong>請填寫管理者 E-mail</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 商店爭議款信箱</mat-label>
                                    <input matInput formControlName="DisputeMail">
                                    <mat-hint>合作商店如發生爭議款相關議題時，可供聯絡的信箱</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.DisputeMail.hasError('required')">
                                        <strong>請填寫商店爭議款信箱</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 客服商店信箱</mat-label>
                                    <input matInput formControlName="MerchantEmail">
                                    <mat-hint>商店客服信箱</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.MerchantEmail.hasError('required')">
                                        <strong>請填寫客服商店信箱</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 商店中文名稱</mat-label>
                                    <input matInput formControlName="MerchantName">
                                    <mat-hint>合作商店中文名稱</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.MerchantName.hasError('required')">
                                        <strong>請填寫管理者中文姓名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 商店英文名稱</mat-label>
                                    <input matInput formControlName="MerchantNameE">
                                    <mat-hint>合作商店英文名稱</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.MerchantNameE.hasError('required')">
                                        <strong>請填寫合作商店英文名稱</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 商店網址</mat-label>
                                    <input matInput formControlName="MerchantWebURL">
                                    <mat-hint>合作商店網址，可填寫網站網址或FB粉絲頁網址(不接受IG、和個人FB專頁)</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.MerchantWebURL.hasError('required')">
                                        <strong>請填寫商店網址</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 行業別</mat-label>
                                    <mat-select formControlName="BusinessType">
                                        <mat-option *ngFor="let b of businessType" [value]="b.value">
                                            {{b.name}}</mat-option>
                                    </mat-select>
                                    <mat-hint>商店主要販售商品類別</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 城市</mat-label>
                                    <mat-select formControlName="MerchantAddrCity"
                                        (selectionChange)="cityChange($event)">
                                        <mat-option *ngFor="let key of objectKeys(zipcode)" [value]="key">
                                            {{key}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p" *ngIf="area">
                                    <mat-label>* 地區</mat-label>
                                    <mat-select formControlName="MerchantAddrArea"
                                        (selectionChange)="areaChange($event)">
                                        <mat-option *ngFor="let key of objectKeys(area)" [value]="key">
                                            {{key}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 詳細地址</mat-label>
                                    <textarea matInput formControlName="MerchantAddr" #twaddr maxlength="60"></textarea>
                                    <mat-hint align="end">{{twaddr.value.length}} / 60</mat-hint>

                                    <mat-error *ngIf="merchantForm.controls.MerchantAddr.hasError('required')">
                                        <strong>請填寫詳細地址</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 商店英文地址</mat-label>
                                    <textarea matInput formControlName="MerchantEnAddr" #enaddr
                                        maxlength="255"></textarea>
                                    <mat-hint align="end">{{enaddr.value.length}} / 255</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.MerchantEnAddr.hasError('required')">
                                        <strong>請填寫商店英文地址</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 營業城市英文名</mat-label>
                                    <mat-select formControlName="CityE">
                                        <mat-option *ngFor="let c of encity" [value]="c.value">{{c.name}}</mat-option>
                                    </mat-select>
                                    <mat-hint>例：Taipei City</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.CityE.hasError('required')">
                                        <strong>請填寫營業城市英文名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 商店簡介</mat-label>
                                    <textarea matInput formControlName="MerchantDesc"></textarea>
                                    <mat-error *ngIf="merchantForm.controls.MerchantDesc.hasError('required')">
                                        <strong>請填寫商店簡介</strong>
                                    </mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 金融機構</mat-label>
                                    <mat-select formControlName="BankCode">
                                        <mat-option *ngFor="let bank of bankData" [value]="bank.code">{{bank.code}}
                                            {{bank.name}}</mat-option>
                                    </mat-select>
                                    <mat-hint>請選擇合作商店金融機構帳戶之金融機構</mat-hint>
                                </mat-form-field> -->
                                <mat-form-field appearance="legacy" class="w80p" *ngIf="bankData.length > 0">
                                    <mat-label>* 金融機構</mat-label>
                                    <input type="text" matInput formControlName="BankCode" [matAutocomplete]="auto">
                                    <button mat-button *ngIf="merchantForm.get('BankCode').value" matSuffix
                                        mat-icon-button aria-label="Clear"
                                        (click)="merchantForm.get('BankCode').setValue('')">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                        [displayWith]="displayFn" (optionSelected)="bankSelected($event)">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{option.code}} {{option.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>請選擇合作商店金融機構帳戶之金融機構</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p" *ngIf="bankBranchData.length > 0">
                                    <mat-label>* 金融機構分行代碼</mat-label>
                                    <input type="text" matInput formControlName="SubBankCode" [matAutocomplete]="autob">
                                    <button mat-button *ngIf="merchantForm.get('SubBankCode').value" matSuffix
                                        mat-icon-button aria-label="Clear"
                                        (click)="merchantForm.get('SubBankCode').setValue('')">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-autocomplete #autob="matAutocomplete" autoActiveFirstOption
                                        [displayWith]="displayFnBranch">
                                        <mat-option *ngFor="let option of filteredOptionsBranch | async"
                                            [value]="option">
                                            {{option.branch_code}} {{option.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>請填入合作商店金融機構帳戶之金融機構分行代碼</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.SubBankCode.hasError('required')">
                                        <strong>請填寫金融機構分行代碼</strong>
                                    </mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 金融機構分行代碼</mat-label>
                                    <input matInput formControlName="SubBankCode">
                                    <mat-hint>請填入合作商店金融機構帳戶之金融機構分行代碼</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.SubBankCode.hasError('required')">
                                        <strong>請填寫金融機構分行代碼</strong>
                                    </mat-error>
                                </mat-form-field> -->
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 金融帳戶帳號</mat-label>
                                    <input matInput formControlName="BankAccount">
                                    <mat-hint>請填入合作商店金融機構帳戶之帳號</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.BankAccount.hasError('required')">
                                        <strong>請填寫金融帳戶帳號</strong>
                                    </mat-error>
                                    <mat-error *ngIf="merchantForm.controls.BankAccount.hasError('pattern')">
                                        <strong>請填寫正確的金融帳戶帳號</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 戶名</mat-label>
                                    <input matInput formControlName="AccountName">
                                    <mat-hint>請填入合作商店金融帳戶之戶名，可接受英文、數字、空格和『-』，『_』符號</mat-hint>
                                    <mat-error *ngIf="merchantForm.controls.AccountName.hasError('required')">
                                        <strong>請填寫金融帳戶之戶名</strong>
                                    </mat-error>
                                    <mat-error *ngIf="merchantForm.controls.AccountName.hasError('pattern')">
                                        <strong>請填寫正確的金融帳戶之戶名</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 每週自動提領設定</mat-label>
                                    <mat-select formControlName="WithdrawSetting">
                                        <mat-option [value]="1">週一</mat-option>
                                        <mat-option [value]="2">週二</mat-option>
                                        <mat-option [value]="3">週三</mat-option>
                                        <mat-option [value]="4">週四</mat-option>
                                        <mat-option [value]="5">週五</mat-option>
                                        <mat-option [value]="6">週六</mat-option>
                                        <mat-option [value]="0">週日</mat-option>
                                    </mat-select>
                                    <mat-hint>例: 當設定為週三時，每週三會將藍新帳戶餘額，匯入至您所設定的金融帳戶</mat-hint>
                                </mat-form-field>
                            </div>
                        </form>
                        <div style="text-align: center; margin-top:20px">
                            <button mat-raised-button matStepperPrevious color="primary"
                                style="margin-right:12px">上一步</button>
                            <button mat-raised-button matStepperNext color="primary" (click)="gogogo()"
                                [disabled]="!typeForm.valid || !baseForm.valid || !merchantForm.valid ||  bindProcess || !agreeForm.valid">
                                申請
                            </button>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </mat-card-content>
        </mat-card>
    </ng-template>

    <!-- 連動成功 -->
    <ng-template [ngIf]="(applyStatus && exist === false) || (exist == true && creditcard == false)">
        <!-- OA連動成功-->
        <mat-card class="box" [ngStyle.lt-md]="{'width': '70%'}" style="width:40%; padding:30px">
            <mat-card-content style="text-align: center; font-size:1em">
                <!-- <img src="../../../../assets/images/marketplace/complete-03.png"
                style="margin:0 auto; display: block; width:82px" /> -->
                <span style="color:#D83183">
                    <mat-icon fontSet="saydou_icon" fontIcon="icn_thumb" style="font-size:90px; width:auto;"></mat-icon>
                </span>
                <br><br>
                <span style="font-weight: bold;">您的集客小舖已送出申請，請等候藍新金流審核</span><br>
                <br><br>
                <ol style="text-align: left; width:55%; margin:0 auto" [ngStyle.lt-md]="{'width': 'auto'}">
                    <li>為啟用信用卡服務，藍新金流需1至2週審核。</li>
                    <li>審核通過後，將會聯繫您，開始使用集客小舖服務。</li>
                </ol>
                <br><br>
                您可使用你所填寫的帳號登入<a href="https://www.newebpay.com/main/login_center/single_login"
                    target="_balnk">藍新金流後台</a>查詢相關資訊
                <br><br>
                <span style="color:#D83183">需要幫助嗎?有任何問題可以聯繫
                    <a href="https://page.line.me/694adzcy" target="_balnk">神美客服</a>
                </span><br>
            </mat-card-content>
        </mat-card>
    </ng-template>
    <ng-template [ngIf]="exist && creditcard">
        <!-- OA連動成功-->
        <mat-card class="box" [ngStyle.lt-md]="{'width': '70%'}" style="width:40%; padding:30px">
            <mat-card-content style="text-align: center; font-size:1em">
                <!-- <img src="../../../../assets/images/marketplace/complete-03.png"
                    style="margin:0 auto; display: block; width:82px" /> -->
                <span style="color:#D83183">
                    <mat-icon fontSet="saydou_icon" fontIcon="icn_thumb" style="font-size:90px; width:auto;"></mat-icon>
                </span>
                <br><br>
                <span style="font-weight: bold;">您的集客小舖，藍新信用卡功能啟用成功，只差最後一步了！</span>
                <br><br>
                <ol style="text-align: left; width:75%; margin:0 auto" [ngStyle.lt-md]="{'width': 'auto'}">
                    <li>請聯絡您的負責業務，為您更換LINE官方帳號的圖文選單及產生集客小舖連結。</li>
                    <li>請重新登入神美網頁，至集客小舖-商品管理功能，上架您的商品及設計作品。</li>
                </ol>
                <br><br>
                您可使用你所填寫的帳號登入<a href="https://www.newebpay.com/main/login_center/single_login"
                    target="_balnk">藍新金流後台</a>查詢相關資訊
                <br><br>
                <span style="color:#D83183">需要幫助嗎?有任何問題可以聯繫
                    <a href="https://page.line.me/694adzcy" target="_balnk">神美客服</a>
                </span><br>
            </mat-card-content>
        </mat-card>
    </ng-template>
</div>
