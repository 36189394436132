import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from 'src/app/services/common/loading-screen.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

    loading: boolean = false;
    loadingSubscription: Subscription;

    constructor(private loadingScreenService: LoadingScreenService) {
    }

    ngOnInit() {
        this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
            this.loading = value;
        });
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
    }

}
