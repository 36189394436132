import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { LoadingScreenService } from './loading-screen.service';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { SaydouSnackBarService } from './saydou-snackbar.service';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helpers';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class SaydouPostInterceptor {

    constructor(private loadingScreenService: LoadingScreenService,
        private _snackBar: SaydouSnackBarService,
        private dialogRef: MatDialog,
        private router: Router) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any>{
        const started = Date.now();
        let ok: string;
        let errMsg: string;
        let defaultResponse = { status: false, message: '', data: '' };
        return next
            .handle(req)
            .pipe(
                tap(
                    // Succeeds when there is a response; ignore other events
                    event =>{
                        ok = event instanceof HttpResponse ? 'succeeded' : '';
                        if (event instanceof HttpResponse) {
                            let body: any = { ...defaultResponse, ...event.body };
                            if (body.status === false && req.headers.get('ignorepermission') === undefined) {
                                if (body.status === false) {
                                    if (body.message) {
                                        this._snackBar.showBar(body.message, "關閉", 'error');
                                    }
                                }
                            } else {
                                if (body.status === false) {
                                    if(body.message) {
                                        this._snackBar.showBar(body.message, "關閉", 'error');
                                    }
                                }
                            }
                        }
                    },
                    // Operation failed; error is an HttpErrorResponse
                    error => ok = 'failed'
                ),
                // Log when response observable either completes or errors
                finalize(() => {
                    if (req.headers.get('ignoreloading') == null) {
                        this.loadingScreenService.stopLoading();
                    }
                    const elapsed = Date.now() - started;
                    const msg = `${req.method} "${req.urlWithParams}"
                    ${ok} in ${elapsed} ms.`;
                    console.log(msg);
                    if (ok === 'failed') {
                        // this._snackBar.showBar("系統執行失敗", "關閉", 'error');
                    }
                }),
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        const body: any = error || '';
                        if (body.error.errors) {
                            const err = body.error.message || JSON.stringify(body);
                            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
                        }

                    } else {
                        errMsg = error.message ? error.message : error.toString();
                    }
                    if (error.status == '401') {
                        // this._snackBar.showBar("", "關閉", 'error');
                        if (req.headers.get('group') == null) {
                            Helpers.removeUser();
                            this.router.navigate(['/login']);
                        } else {
                            Helpers.removeGroupUser();
                            this.router.navigate(['/group/login']);
                        }
                        this.dialogRef.closeAll();
                    } if (error.status == '403') {
                        this._snackBar.showBar("沒有執行此動作的權限", "關閉", 'error');
                        // Helpers.removeUser();
                        // this.router.navigate(['/login']);
                    }
                    if (error.status == '422') {
                        const body: any = error || '';
                        let invalidMsg = [];
                        if (body.error.errors) {
                            for (const key in body.error.errors) {
                                const e = body.error.errors[key];
                                invalidMsg.push(e);
                            }
                        }
                        this._snackBar.showBar(invalidMsg.join(", "), "關閉", 'error');
                    }
                    if (error.status == '500') {
                        this._snackBar.showBar("系統錯誤", "關閉", 'error');
                    }
                    console.log(errMsg);
                    return of([]);
                })
            );
    }
}
