<mat-toolbar style="background-color:#873e74; position: fixed; top:0; z-index: 501;">
    <mat-toolbar-row>
        <img src="../../../assets/images/saydou.png"
            style="width:35px; object-fit: cover; height: 35px; border-radius: 15%; margin-right:20px" />
        <span style="color:white">SayDou 神美</span>
        <span class="toolbar-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="login_panel" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md=" center"
    [ngStyle.lt-md]="{'margin-top' : '56px'}">
    <ng-template [ngIf]="attachStatus !== 'yes'">
        <mat-card class="box" [ngStyle.lt-md]="{'width': '95%','margin-top': '11px'}" style="width:45%;"
            *ngIf="baseDatas$ | async as baseDatas;">
            <!-- <img *ngIf="!mobileMatches" mat-card-image src="../../../assets/images/saydou_logo.png"
                        style="width:350px; margin:0 auto"> -->
            <!-- <div class="panel-image" [ngStyle.lt-md]="{'width': '170px'}" style="width:200px">
                    </div> -->
            <mat-card-content [ngStyle.lt-md]="{'font-size': '14px'}">
                <carousel [margin]="0" [height]="mobileMatches ? 300 : 500" [arrows]="mobileMatches ? false : true"
                    [dots]="mobileMatches ? false : true" [objectFit]="mobileMatches ? 'contain' : 'cover'"
                    [cellWidth]="'100%'" [arrowsOutside]="false" [autoplay]="true" [dots]="true" [loop]="true"
                    [images]="images" [autoplayInterval]="5000" style="z-index:500">
                </carousel>
                <mat-horizontal-stepper linear #stepper>
                    <mat-step [stepControl]="agreeForm" [editable]="true">
                        <div>
                            用自己的LINE官方帳號即刻打造專屬預約，整合工作行事曆。<br>
                            客人不用下載APP就能預約。<br>
                            一個帳號就能擁有預約、會員、排班、開單、POS、薪資、報表、OMO、社群、行銷的一條龍全方位系統服務！<br>
                            新客、熟客，即刻也集客。
                        </div>
                        <form [formGroup]="agreeForm">
                            <ng-template matStepLabel>使用注意事項</ng-template>
                            <div>
                                <h4>使用注意事項</h4>
                                <p>如果您同時使用 Messaging API，則市集上提供的外掛模組可能無法正常運行。<br>使用外掛模組時，請注意不要同時使用 Messaging API。<br>對於
                                    Messagaing API
                                    設置，請確保您具有以下狀態之一：</p>
                                <ol>
                                    <li><a href="https://tw.linebiz.com/manual/line-official-account/oa-manager"
                                            target="_blank">LINE 官方帳號管理後台 » 設定
                                            » Messaging API</a> 狀態未使用</li>
                                    <li><a href="https://tw.linebiz.com/manual/line-official-account/oa-manager-autoreply"
                                            target="_blank">LINE
                                            官方帳號管理後台 » 設定 » 回應設定 » 進階設定</a>中的 Webhook 項目必須為停用</li>
                                    <li><a href="https://tw.linebiz.com/manual/line-official-account/oa-manager-chatting"
                                            target="_blank">LINE
                                            官方帳號管理後台 » 設定 » 回應設定 » 基本設定</a>中的回應模式設置必須為聊天模式</li>
                                </ol>
                                <p>一次只能使用市集上提供的一個外掛模組。<br>請注意不要訂閱多個外掛模組。</p>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="15px">
                                <mat-checkbox style="margin:0 auto" formControlName="agree">
                                    我同意<a href="https://www.saydou.com/line-oa-marketplace-terms/"
                                        target="_blank">使用條款</a>和<a href="https://www.saydou.com/privacy-policy/"
                                        target="_blank">隱私權條款</a>
                                </mat-checkbox>
                            </div>
                            <div style="text-align: right;">
                                <button mat-raised-button matStepperNext color="primary"
                                    [disabled]="!agreeForm.valid">下一步</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="applyForm" [editable]="true">
                        <form [formGroup]="applyForm">
                            <ng-template matStepLabel>填寫基本資料</ng-template>
                            <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px">
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 聯絡人</mat-label>
                                    <input matInput formControlName="contact">
                                    <mat-error *ngIf="applyForm.controls.contact.hasError('required')">
                                        <strong>請填寫聯絡人</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* E-mail</mat-label>
                                    <input matInput formControlName="email">
                                    <mat-hint>請填寫正確可收信的信箱</mat-hint>
                                    <mat-error *ngIf="applyForm.controls.email.hasError('required')">
                                        <strong>請填寫E-mail</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 手機</mat-label>
                                    <input matInput formControlName="mobile">
                                    <mat-hint>請留下你的手機，之後將會透過手機簡訊驗證</mat-hint>
                                    <mat-error *ngIf="applyForm.controls.mobile.hasError('required')">
                                        <strong>請填寫手機</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 品牌名稱</mat-label>
                                    <input matInput formControlName="brand_name">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="applyForm.controls.brand_name.hasError('required')">
                                        <strong>請填寫品牌名稱</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 地址</mat-label>
                                    <input matInput formControlName="address">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="applyForm.controls.address.hasError('required')">
                                        <strong>請填寫地址</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 產業別</mat-label>
                                    <mat-select formControlName="industry">
                                        <mat-option *ngFor="let industry of industryData"
                                            [value]="industry.industry_id">
                                            {{industry.industry_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 帳號</mat-label>
                                    <input matInput formControlName="account">
                                    <mat-hint>最多輸入20個英文，數字</mat-hint>
                                    <mat-error *ngIf="applyForm.controls.account.hasError('required')">
                                        <strong>請填寫帳號</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 密碼</mat-label>
                                    <input matInput formControlName="password" type="password">
                                    <mat-hint>請輸入6個以上至20個英文數字</mat-hint>
                                    <mat-error *ngIf="applyForm.controls.password.hasError('required')">
                                        <strong>請填寫密碼</strong>
                                    </mat-error>
                                    <mat-error *ngIf="applyForm.controls.password.hasError('minlength')">
                                        <strong>必需</strong>要有6個字以上
                                    </mat-error>
                                    <mat-error *ngIf="applyForm.controls.password.hasError('maxlength')">
                                        <strong>不得超過</strong>20個字
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" class="w80p">
                                    <mat-label>* 確認密碼</mat-label>
                                    <input matInput formControlName="confirmPass" [errorStateMatcher]="errorMatcher"
                                        type="password">
                                    <mat-hint>再輸入一次密碼</mat-hint>
                                    <mat-error *ngIf="applyForm.hasError('notSame')">
                                        密碼不一致
                                    </mat-error>
                                </mat-form-field>
                                <ng-template [ngIf]="showDiscount">
                                    <h2>振興專案優惠</h2>
                                    <mat-form-field appearance="none" class="w80p" [ngStyle.lt-md]="{'width': '100%'}">
                                        <mat-checkbox formControlName="discount_three" [disableRipple]="true"
                                            (click)="$event.preventDefault()">買6個月送6個月優惠專案 $2,208</mat-checkbox>
                                        <input matInput hidden>
                                        <mat-hint class="text-color-primary">1個帳號 / 368元 / 月</mat-hint>
                                    </mat-form-field>
                                    <!-- <mat-form-field appearance="none" class="w80p" style="height:100px" [ngStyle.lt-md]="{'width': '100%'}">
                                        <mat-checkbox formControlName="discount_premium_id">專屬LINE官方帳號ID</mat-checkbox>
                                        <input matInput hidden>
                                        <mat-hint class="text-color-primary">使用 Saydou 模組於 30 日內，好友數增加達 150 個，可免費獲得「專屬 ID」一年 (原價 $720/年)</mat-hint>
                                    </mat-form-field> -->
                                    <mat-form-field appearance="none" class="w80p" style="height:100px"
                                        [ngStyle.lt-md]="{'width': '100%'}">
                                        <mat-checkbox formControlName="discount_beacon">LINE Beacon增粉神器 </mat-checkbox>
                                        <input matInput hidden>
                                        <mat-hint class="text-color-primary">
                                            使用SayDou模組加購LINE Beacon方案(原價$12000)，本活動特惠價格 $3600/年(含硬體費用)
                                        </mat-hint>
                                    </mat-form-field>

                                </ng-template>
                                <!-- <mat-form-field appearance="none" class="w80p">
                                    <textarea matInput hidden></textarea>
                                    <mat-checkbox formControlName="argeeMenu" class="text-color-primary w80p">
                                        同意SayDou集客預約在綁定成功後，置換您目前所設定的LINE圖文選單
                                    </mat-checkbox>
                                </mat-form-field> -->
                            </div>
                        </form>
                        <div style="text-align: center; margin-top:20px">
                            <!-- <strong class="text-color-primary">**綁定成功將會置換您目前所設定的LINE圖文選單**</strong> -->
                            <button mat-raised-button color="primary" class="w80p"
                                [disabled]="!agreeForm.valid || !applyForm.valid || bindProcess" (click)="gogogo()">開始綁定
                                LINE
                                官方帳號</button>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </mat-card-content>
        </mat-card>
    </ng-template>

    <!-- 連動成功 -->
    <ng-template [ngIf]="attachStatus == 'yes'">
        <!-- OA連動成功-->
        <mat-card *ngIf="account == 'no'" class="box" [ngStyle.lt-md]="{'width': '70%'}"
            style="width:40%; padding:30px">
            <mat-card-content style="text-align: center; font-size:1em">
                <!-- <img src="../../../../assets/images/marketplace/complete-03.png"
                style="margin:0 auto; display: block; width:82px" /> -->
                <span style="color:#D83183">
                    <mat-icon fontSet="saydou_icon" fontIcon="icn_mail" style="font-size:90px; width:auto;"></mat-icon>
                </span>
                <br><br>
                <span style="font-weight: bold;">查看您的Email</span><br>
                我們已將驗證信寄至您所填寫的Email<br>
                請點選信中連結完成驗證<br>
                <span style="color:#D83183">*驗證連結24小時內有效</span><br>
            </mat-card-content>
        </mat-card>
        <!-- Email認證成功-->
        <mat-card *ngIf="account === 'yes' && error === ''" class="box" [ngStyle.lt-md]="{'width': '70%'}"
            style="width:40%; padding:30px">
            <mat-card-content style="text-align: center; font-size:1em">
                <span style="color:#D83183">
                    <mat-icon fontSet="saydou_icon" fontIcon="icn_thumb" style="font-size:90px; width:auto;"></mat-icon>
                </span>
                <br><br>
                <h3 style="font-weight: bold;">只差最後一步了！</h3>
                我們已將您的<strong style="color:#D83183">客戶代號</strong>及<strong
                    style="color:#D83183">帳號</strong>發送至您的Email<br>
                請再次至您的信箱查看<br>
            </mat-card-content>
        </mat-card>
        <!-- Email認證失敗 or 驗證失效-->
        <mat-card *ngIf="account === 'yes' && error !== '' && error !== '088449'" class="box" [ngStyle.lt-md]="{'width': '70%'}"
            style="width:40%; padding:30px">
            <mat-card-content style="text-align: center; font-size:1em">
                <h3 style="color:red">Email驗證失敗！</h3>
                錯誤訊息: {{error}}<br>
                請您聯絡SayDou神美客服<br><br>
                <a href="https://lin.ee/k1OzVCl"><img
                        src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" alt="加入好友" height="36"
                        border="0"></a>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="account === 'yes' && error === '088449'" class="box" [ngStyle.lt-md]="{'width': '70%'}"
            style="width:40%; padding:30px">
            <mat-card-content style="text-align: center; font-size:1em">
                <h3 style="color:red">您已經申請過試用帳號！</h3>
                請問在試用期間有什麼問題嗎?<br>
                歡迎您聯絡SayDou神美客服<br><br>
                <a href="https://lin.ee/k1OzVCl"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                        alt="加入好友" height="36" border="0"></a>
            </mat-card-content>
        </mat-card>
    </ng-template>
</div>
