import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../helpers';
import { Router } from '@angular/router';
@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

    isLogin: boolean = false;
    userInfo;
    constructor(private router: Router) { }
    ngOnInit() {
        this.userInfo = Helpers.getUser();
    }
    ngAfterViewInit() {

    }
    logout () {
        Helpers.removeUser();
        this.router.navigate(['/login']);
    }
    enterBranch(storid) {
        this.router.navigate(['/branch', storid]);
    }
}
