import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Helpers } from "../helpers";
@Injectable()
export class CheckoutGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (Helpers.checkLogin()) {
            if (Helpers.getCheckoutPermission()) {
                return true;
            } else {
                var link = Helpers.getDefaultFunction(state.url);
                console.log(link);
                if (link != "") {
                    this.router.navigate([link]);
                } else {
                    this.router.navigate(["/denied"]);
                }
                return false;
            }
        } else {
            this.router.navigate(["/login"]);
            return false;
        }
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (Helpers.checkLogin()) {
            if (Helpers.getCheckoutPermission()) {
				return true;
			} else {
                var link = Helpers.getDefaultFunction(state.url);
                console.log(link);
				if (link != "") {
					this.router.navigate([link]);
				} else {
                    this.router.navigate(["/denied"]);
				}
				return false;
            }
        } else {
            this.router.navigate(["/login"]);
            return false;
        }
	}
}
