<mat-toolbar style="background-color:#873e74; position: fixed; top:0; z-index: 501;">
    <mat-toolbar-row>
        <img src="../../../assets/images/saydou.png"
            style="width:35px; object-fit: cover; height: 35px; border-radius: 15%; margin-right:20px" />
        <a style="color:white" href="/">SayDou 神美</a>
        <span class="toolbar-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="login_panel" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md=" center"
    [ngStyle.lt-md]="{'margin-top' : '56px'}">
    <mat-card class="box" [ngStyle.lt-md]="{'width': '95%','margin-top': '11px'}" style="width:65%;"
        ><!-- *ngIf="baseDatas$ | async as baseDatas;" -->
        <mat-card-content *ngIf="!checkoutSuccess && !signReady && !contractDeletedShow && !contactMissingShow" [ngStyle.lt-md]="{'font-size': '14px'}">
            <mat-horizontal-stepper linear #stepper>
                <mat-step [stepControl]="baseForm"  [editable]="true">
                    <form [formGroup] = "baseForm">
                        <ng-template matStepLabel>填寫基本資料</ng-template>
                        <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="30px">
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>* 品牌名稱</mat-label>
                                <input matInput formControlName="custname">
                                <mat-error *ngIf="baseForm.controls.custname.hasError('required')">
                                    <strong>請填寫品牌名稱</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>* 品牌負責人</mat-label>
                                <input matInput formControlName="siname">
                                <mat-error *ngIf="baseForm.controls.siname.hasError('required')">
                                    <strong>請填寫品牌負責人名稱</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>* 城市</mat-label>
                                <mat-select formControlName="addrCity"
                                    (selectionChange)="cityChange($event)">
                                    <mat-option *ngFor="let key of objectKeys(zipcode)" [value]="key">
                                        {{key}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p" *ngIf="area">
                                <mat-label>* 地區</mat-label>
                                <mat-select formControlName="addrArea"
                                    (selectionChange)="areaChange($event)">
                                    <mat-option *ngFor="let key of objectKeys(area)" [value]="key">
                                        {{key}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>* 詳細地址</mat-label>
                                <input matInput formControlName="address">
                                <mat-error *ngIf="baseForm.controls.address.hasError('required')">
                                    <strong>請填寫詳細地址</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>* 手機</mat-label>
                                <input matInput formControlName="phone">
                                <mat-error *ngIf="baseForm.controls.phone.hasError('required')">
                                    <strong>請填寫手機</strong>
                                </mat-error>
                                <mat-error *ngIf="baseForm.controls.phone.hasError('pattern')">
                                    <strong>請填寫正確格式，例：09xx000111</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>* 信箱</mat-label>
                                <input matInput formControlName="email">
                                <mat-error *ngIf="baseForm.controls.email.hasError('required')">
                                    <strong>請填寫email</strong>
                                </mat-error>
                                <mat-error *ngIf="baseForm.controls.email.hasError('email')">
                                    <strong>email格式不正確</strong>
                                </mat-error>
                                <mat-hint>例：example@example.com</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="legacy" class="w80p">
                                <mat-label>統一編號</mat-label>
                                <input matInput formControlName="taxnum">
                                <mat-hint>非必填</mat-hint>
                                <mat-error *ngIf="baseForm.controls.taxnum.hasError('taxnum')">
                                    統編格式錯誤
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div style="text-align: right;margin-top:10px;">
                            <!-- [disabled]="!baseForm.valid" -->
                            <button mat-raised-button matStepperNext color="primary"
                                [disabled]="!baseForm.valid">下一步</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="agreeForm" [editable]="true">
                    <form [formGroup] = "agreeForm">
                        <ng-template matStepLabel>合約內容</ng-template>
                        <div>
                            <contract-content [customerInfoData]="{}" [billDetail]="billDetail" [totalPrice]="totalPrice"
                                [customerInfo]="baseForm.value" [contractDirection]="contractDirection" [suadInfo]="suadInfo"
                                [bill]="bill" [systemDetail]="systemDetail" [systemTotal]="systemTotal"
                                [hardwareDetail]="hardwareDetail" [hardwareTotal]="hardwareTotal"></contract-content>
                        </div>
                        <br>
                        <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="15px">
                            <mat-checkbox style="margin:0 auto" formControlName="contract_content_agree">
                                已詳讀合約內容
                            </mat-checkbox>
                        </div>
                        <div style="text-align: right;margin-top:10px;">
                            <button mat-raised-button matStepperPrevious color="primary"
                                style="margin-right:12px">上一步</button>
                            <button mat-raised-button matStepperNext color="primary"
                            [disabled]="!agreeForm.valid || !baseForm.valid">下一步</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="signForm"  [editable]="true">
                    <form [formGroup] = "signForm">
                    <ng-template matStepLabel>客戶簽名</ng-template>
                        <div #signArea style="border:1px solid black;height: 400px;">
                            <!-- width="900" height="400" -->
                            <canvas #signPadCanvas ></canvas>
                        </div>
                        <div style="text-align: right">
                            <button mat-raised-button color="primary" (click)="clear()" class="clear" style="margin-top:10px">清除</button>
                            <button mat-raised-button matStepperPrevious color="primary"
                                style="margin-right:12px;margin-top:10px">上一步</button>
                            <button (click)="saveConfirm()" [disabled]="!signForm.valid || !baseForm.valid || !agreeForm.valid" mat-raised-button matStepperNext color="primary" style="margin-top:10px">確認送出</button>
                        </div>
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
        </mat-card-content>
        <div *ngIf="checkoutSuccess" style="min-height:500px">
            <div style="padding:50px 0px;text-align: center;">
                <div style="font-size: 30px;">
                    合約已完成！請確認您的合約資訊
                </div>
                <div style="text-align:left;padding:0px 50px;">
                    <div style="display:flex;align-items: center;">
                        <h3>
                            合約起始日：
                        </h3>
                        <div>
                            民國{{ contractDirection['start'] }}
                        </div>
                    </div>
                    <div>
                        <h3>
                            付款項目
                        </h3>
                        <span>
                            <table style="width:80%;margin:auto;border-collapse: collapse;">
                                <tr style="text-align:center;">
                                    <th>項目</th>
                                    <th>啟用時期</th>
                                    <th>數量</th>
                                    <th>單價</th>
                                    <th>費用</th>
                                </tr>
                                <tr *ngIf="systemDetail.length > 0" style="border-top:1px solid #6f727d;font-weight:bold">
                                    <td colspan="2">系統費用小計</td>
                                    <td colspan="3" style="text-align:end">
                                        {{systemTotal|number:'1.0'}}
                                    </td>
                                </tr>
                                <ng-template ngFor let-item [ngForOf]="systemDetail">
                                    <tr style="border-top:1px solid #6f727d">
                                        <td style="padding-left:15px;">
                                            {{ item['item__'] }}
                                        </td>
                                        <td>
                                            {{ item['version'] ? item['version']['duration'] + '個月' : '' }}
                                        </td>
                                        <td style="text-align:end">
                                            {{ item['price_']|number:'1.0' }}
                                        </td>
                                        <td style="text-align:end">
                                            {{ item['quantity']|number:'1.0' }}
                                        </td>
                                        <td style="text-align:end">
                                            {{ item['tprice']|number:'1.0' }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <tr *ngIf="bill['discot'] > 0" style="border-top:1px solid #6f727d">
                                    <td style="padding-left:15px;">
                                        系統費用折扣 {{ bill['disper'] % 10 == 0 ? bill['disper'] / 10 : bill['disper'] }}折
                                    </td>
                                    <td style="text-align:end">
                                    </td>
                                    <td style="text-align:end">
                                    </td>
                                    <td style="text-align:end">
                                    </td>
                                    <td style="text-align:end">
                                        -{{ bill['discot']|number:'1.0' }}
                                    </td>
                                </tr>
                                <tr *ngIf="hardwareDetail.length > 0" style="border-top:1px solid #6f727d;font-weight:bold">
                                    <td colspan="2">硬體與其他費用小計</td>
                                    <td colspan="3" style="text-align:end">
                                        {{hardwareTotal|number:'1.0'}}
                                    </td>
                                </tr>
                                <ng-template ngFor let-item [ngForOf]="hardwareDetail">
                                    <tr style="border-top:1px solid #6f727d">
                                        <td style="padding-left:15px;">
                                            {{ item['item__'] }}
                                        </td>
                                        <td>
                                            {{ item['version'] ? item['version']['duration'] + '個月' : '' }}
                                        </td>
                                        <td style="text-align:end">
                                            {{ item['price_']|number:'1.0' }}
                                        </td>
                                        <td style="text-align:end">
                                            {{ item['quantity']|number:'1.0' }}
                                        </td>
                                        <td style="text-align:end">
                                            {{ item['tprice']|number:'1.0' }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <tr *ngIf="bill['coupon_value'] > 0" style="border-top:1px solid #6f727d">
                                    <td style="font-weight:bold">
                                        {{ bill['coupon_name']}}
                                    </td>
                                    <td style="text-align:end">
                                    </td>
                                    <td style="text-align:end">
                                    </td>
                                    <td style="text-align:end">
                                    </td>
                                    <td style="text-align:end;font-weight:bold">
                                        -{{ bill['coupon_value']|number:'1.0' }}
                                    </td>
                                </tr>
                            </table>
                        </span>
                    </div>
                    <div style="display:flex;align-items: center;">
                        <h3>總價：</h3>
                        <div style="font-weight:bold">NT${{ totalPrice|number:'1.0' }}</div>
                    </div>
                    <h3>匯款資訊</h3>
                    <div style="font-size:15px;font-weight: bold;">
                        銀行資料：國泰世華銀行 013 林口分行<br>
                        帳號：134030-005119<br>
                        帳戶：安騰創新生活科技有限公司<br><br>
                    </div>
                    <div><span style="color:red">*</span>如您要匯款，請截圖此頁資訊，詳細合約內容與資訊已寄至您的信箱，請查閱</div>
                    <br>
                    <div><span style="color:red">*</span>付款完成後，請<span style="font-size:15px;font-weight: bold;">「拍照／截圖／提供末五碼」</span>等方式回傳至SayDou神美群組或所屬管理師，確認款項是否付款成功，俾利後續開通作業流程。</div>
                </div>
                <button mat-raised-button color="primary" (click)="showSign()" style="margin-right:12px;margin-top:50px">
                    顯示簽名
                </button>
                <button mat-raised-button color="primary" (click)="download()" style="margin-right:12px;margin-top:50px">
                    合約下載
                </button>
                <button mat-raised-button color="primary" (click)="redirectWeb()" style="margin-right:12px;margin-top:50px">
                    返回網頁
                </button>
            </div>
        </div>
        <div *ngIf="signReady" style="height:500px">
            <div style="padding:200px 40px;text-align: center;">
                <div style="font-size: 24px;">
                    合約已完成！如有問題請聯繫神美業務
                </div>
                <button mat-raised-button color="primary" (click)="redirectWeb()" style="margin-right:12px;margin-top:50px">
                    返回網頁
                </button>
            </div>
        </div>
        <div *ngIf="contractDeletedShow" style="height:500px">
            <div style="padding:200px 40px;text-align: center;">
                <div style="font-size: 24px;">
                    合約已刪除！如有問題請聯繫神美業務
                </div>
                <button mat-raised-button color="primary" (click)="redirectWeb()" style="margin-right:12px;margin-top:50px">
                    返回網頁
                </button>
            </div>
        </div>
        <div *ngIf="contactMissingShow" style="height:500px">
            <div style="padding:200px 40px;text-align: center;">
                <div style="font-size: 24px;">
                    聯絡人尚未做填寫！請聯繫神美業務做聯絡人填寫
                </div>
                <button mat-raised-button color="primary" (click)="redirectWeb()" style="margin-right:12px;margin-top:50px">
                    返回網頁
                </button>
            </div>
        </div>
    </mat-card>
</div>
