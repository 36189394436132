import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class PaymentflowService {
    baseUrl: string = environment.backend.baseURL;
    constructor(private http: HttpClient) { }
    apply(body) {
        return this.http.post<any>(`${this.baseUrl}/api/payflow/apply/newebpay`, body);
    }
    modify(body) {
        return this.http.post<any>(`${this.baseUrl}/api/payflow/modify/newebpay`, body);
    }
    checkExist() {
        return this.http.get<any>(`${this.baseUrl}/api/payflow/checkExist/newebpay`);
    }
    checkPayStatus(storid) {
        return this.http.get<any>(`${this.baseUrl}/api/payflow/checkPayStatus/newebpay/${storid}`);
    }
    storeList() {
        return this.http.get<any>(`${this.baseUrl}/api/payflow/storeList/newebpay`);
    }
    private ignoreLoadingHesders() {
        return { headers: { 'ignoreloading': '1' } };
    }
}
