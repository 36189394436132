import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MediaChangeService } from 'src/app/services/common/media-change.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BindingService } from '../../../services/binding.service';
import { SaydouSnackBarService } from 'src/app/services/common/saydou-snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as moment from 'moment';
@Component({
    selector: 'app-line-authorize',
    templateUrl: './authorize.component.html',
    styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {
    model: any = {};
    agreeForm: FormGroup;
    applyForm: FormGroup;
    returnUrl: string;
    baseUrl: string = environment.backend.baseURL;
    goUrl: string = this.baseUrl;
    siteKey: string = environment.recaptchaKey;
    mobileMatches: boolean;
    errorStateMatcher: ErrorStateMatcher;
    errorMatcher = new MyErrorStateMatcher();
    baseDatas$: Observable<any>;
    industryData = [];
    industrySelections: number[];
    state = '';
    attachStatus = 'no';
    account = 'no';
    error = "";
    bindProcess = false;
    images = [
        { path: '../../../../assets/images/marketplace/marketplace_1.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_2.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_3.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_4.jpg' },
        { path: '../../../../assets/images/marketplace/marketplace_5.jpg' },
    ];
    discountStart = moment("20211201", "YYYYMMDD").startOf('day');
    discountEnd = moment("20220131", "YYYYMMDD").endOf('day');
    showDiscount = false;
    constructor(private http: HttpClient,
        private fb: FormBuilder,
        private mc: MediaChangeService,
        private bs: BindingService,
        private route: ActivatedRoute,
        private _snackBar: SaydouSnackBarService,
        private recaptchaV3Service: ReCaptchaV3Service,
    ) { }

    ngOnInit() {
        if(moment().isBetween(this.discountStart, this.discountEnd)){
            this.showDiscount = true;
        }
        this.mc.matches.subscribe((matches: boolean) => {
            this.mobileMatches = matches;
        });
        this.route.queryParams.subscribe(params => {
            if (Object.prototype.hasOwnProperty.call(params, 'state')) {
                this.state = params.state;
            }
            if (Object.prototype.hasOwnProperty.call(params, 'error')) {
                this.error = params.error;
                this._snackBar.showBar(params.error, '', 'error');
            }
            if (Object.prototype.hasOwnProperty.call(params, 'success')) {
                this.attachStatus = params.success;
            }
            if (Object.prototype.hasOwnProperty.call(params, 'account')) {
                this.account = params.account;
            }
        });
        this.applyForm = this.fb.group({
            id: '',
            contact: ['', [Validators.required]],
            mobile: ['', [Validators.required, Validators.pattern(/^09[0-9]{8}$/)]],
            email: ['', [Validators.required, Validators.email]],
            brand_name: ['', [Validators.required]],
            address: ['', [Validators.required, Validators.maxLength(128)]],
            account: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[A-Za-z0-9_]{3,20}$')]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern('^[A-Za-z0-9_]{6,20}$')]],
            confirmPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern('^[A-Za-z0-9_]{6,20}$')]],
            industry: [0, [Validators.min(1), Validators.required]],
            discount_three: this.showDiscount,
            discount_premium_id: false,
            discount_beacon: false,
            // argeeMenu: [false, Validators.requiredTrue]
        }, { validator: this.checkPasswords });
        this.agreeForm = this.fb.group({
            agree: [false, Validators.requiredTrue]
        });
        if (!environment.production) {
            this.baseUrl = "http://api.sayweb/api";
            this.goUrl = "https://45ca21d1b558.ngrok.io";
            this.applyForm.get('contact').setValue('Gordon');
            this.applyForm.get('mobile').setValue('0982850874');
            this.applyForm.get('email').setValue('starocean11@gmail.com');
            this.applyForm.get('brand_name').setValue('testing');
            this.applyForm.get('address').setValue('桃園市龜山區文興路146-1號');
            this.applyForm.get('account').setValue('admin');
            this.applyForm.get('password').setValue('123456');
            this.applyForm.get('confirmPass').setValue('123456');
            this.applyForm.get('industry').setValue(0);
        } else {
            this.baseUrl = this.baseUrl + "/api";
        }
        this.fetchBaseData();
    }

    fetchBaseData() {
        let requestArr: Observable<any>[] = [];
        requestArr.push(this.http.get(`${this.baseUrl}/marketplace/industry`, { headers: { 'ignorepermission': '1' } }));
        if (this.state === '') {
            requestArr.push(of({ status: false }));
        } else {
            let param: any = { state: this.state };
            requestArr.push(this.bs.tempData(param));
        }
        this.baseDatas$ = forkJoin(requestArr).pipe(map(([industryData, tempData]) => {
            this.industryData = industryData.status ? industryData.data : [];
            if (tempData.status) {
                if (tempData.data.id !== undefined) {
                    this.applyForm.get('id').setValue(tempData.data.id);
                    this.applyForm.get('contact').setValue(tempData.data.contact);
                    this.applyForm.get('mobile').setValue(tempData.data.mobile);
                    this.applyForm.get('email').setValue(tempData.data.email);
                    this.applyForm.get('brand_name').setValue(tempData.data.brand_name);
                    this.applyForm.get('address').setValue(tempData.data.address);
                    this.applyForm.get('account').setValue(tempData.data.account);
                    tempData.data.industry = tempData.data.industry.map(x => parseInt(x));
                    this.applyForm.get('industry').setValue(tempData.data.industry);
                }
            }
            return true;
        }
        ));
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmPass').value;
        return pass === confirmPass ? null : { notSame: true }
    }

    gogogo() {
        this.bindProcess = true;
        let v = this.applyForm.getRawValue();
        this.recaptchaV3Service.execute('importantAction')
            .subscribe((token) => {
                v.token = token;
                this.bs.bindingOfficialAccount(v).subscribe(res => {
                    if (res.status) {
                        let state = res.code;
                        const clientId = environment.lineClientId;
                        window.open(`https://manager.line.biz/module/auth/v1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${this.goUrl}/marketplace/authorization&scope=message:send%20message:receive%20account:manage%20profile:read%20message:mark_as_read&state=${state}&region=TW`, "_self");
                    } else {
                        if(res.error_code) {
                            if(res.error_code == '088449') {
                                this.attachStatus = 'yes';
                                this.account = 'yes';
                                this.error = res.error_code;
                            }
                        }
                    }
                    this.bindProcess = false;
                });
            });
    }

    changed() {
        // if (this.applyForm.get('industry').value.length <= 3) {
        //     this.industrySelections = this.applyForm.get('industry').value;
        // } else {
        //     this.applyForm.get('industry').setValue(this.industrySelections);
        // }
    }
}

class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        let invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        if (control.parent.errors == null) {
            invalidParent = false;
        }
        return (invalidCtrl || invalidParent);
    }
}
