import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MediaChangeService {
    public matches = new BehaviorSubject<boolean>(undefined);
    public sidenavOpen = new BehaviorSubject<boolean>(undefined);
    public mobileQuery;
    constructor(private media: MediaMatcher) {
        this.mobileQuery = media.matchMedia('(max-width: 768px)');
        this.mobileQuery.addEventListener("change", this.mediaListener.bind(this));
        this.matches.next(this.mobileQuery.matches);
        this.sidenavOpen.next(true);
    }

    public mediaListener(event) {
        this.matches.next(event.matches);
    }

    public toggleSideNav(toggle) {
        this.sidenavOpen.next(toggle);
    }
}
