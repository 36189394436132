import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from './angular-material.module';
import { LoadingModule } from './loading.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { NotfoundComponent } from './components/common/notfound/notfound.component';
import { DeniedComponent } from './components/common/denied/denied.component';
import { IndexComponent } from './portal/index/index.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SaydouPreInterceptor } from './services/common/saydou-pre-interceptor.service';
import { SaydouPostInterceptor } from './services/common/saydou-post-interceptor.service';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { LoginGuard } from './auth/login.guard';
import { AuthGuard } from './auth/auth.guard';
import { NewebpayGuard } from './auth/newebpay.guard';
import { RedirectGuard } from './auth/redirect.guard';
import { CheckoutGuard } from './auth/checkout.guard';
import { CheckoutDeactiveGuard } from './auth/checkout.deactive.guard';
import { MediaChangeService } from './services/common/media-change.service';
import { CommonService } from './services/common/common.service';
import zh from '@angular/common/locales/zh-Hant';
import { registerLocaleData } from '@angular/common';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AuthorizeComponent } from './components/line/auth/authorize.component';
import { NewebpayApplyComponent, ShopContract } from './components/newebpay/apply/newebpay.apply.component';
import { NewebpayInfoComponent } from './components/newebpay/info/newebpay.info.component';
import { OnlineContractComponent, ContractContent, SignatureDialog } from './components/online-contract/online-contract.component';
import { ContractService } from './services/contract.service';
import { environment } from 'src/environments/environment';
registerLocaleData(zh);
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        IndexComponent,
        LoadingComponent,
        NotfoundComponent,
        DeniedComponent,
        AuthorizeComponent,
        NewebpayApplyComponent,
        NewebpayInfoComponent,
        ShopContract,
        OnlineContractComponent,
        ContractContent,
        SignatureDialog
    ],
    imports: [
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AngularMaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        RecaptchaV3Module,
        LoadingModule,
        IvyCarouselModule,
    ],
    providers: [
        LoginGuard,
        AuthGuard,
        NewebpayGuard,
        RedirectGuard,
        CheckoutGuard,
        CheckoutDeactiveGuard,
        MediaChangeService,
        CommonService,
        ContractService,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaKey },
        { provide: LOCALE_ID, useValue: "zh-Hant" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SaydouPreInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SaydouPostInterceptor,
            multi: true,
        },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000, verticalPosition: 'top' } },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
