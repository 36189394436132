import * as moment from "moment";
import { HOLIDAY_TYPE, MEMBER_EDIT_PERMISSION } from "./const/common.const";
import { CASHPAY_PAYMENTS_NAME, DEFAULT_CASHPAY_DISPLAY, PAYMENT } from "./const/payment.const";

export class Helpers {
    static charColors = [
        "#af4585",
        "#c18aae",
        "#c28cb0",
        "#693678",
        "#90679b",
        "#af90b7",
        "#ca90a6",
        "#d9b0bf",
        "#e5c7d1",
        "#00276f",
        "#495b94",
        "#7885b1",
        "#192241",
        "#525772",
        "#7f8297",
    ];

    static checkLogin() {
        let token = this.getToken();
        if (token) {
            return true;
        } else {
            return false;
        }
    }

    static checkGroupLogin() {
        let token = this.getGroupToken();
        if (token) {
            return true;
        } else {
            return false;
        }
    }

    static checkPermission(url) {
        var result = false;
        var user = this.getUser();
        var urlArray = url.split("/");
        if (user) {
            if (urlArray[1] == 'management') {
                if (user.management) {
                    user.management.forEach(element => {
                        if (result) return;
                        if (element.route) {
                            if (url.indexOf(element.route) >= 0) {
                                result = true;
                            }
                        }
                        element.children.forEach(e => {
                            if (url.indexOf(e.route) >= 0 && e.route != "") {
                                result = true;
                            }
                        });
                    });
                }
            }
        }
        return result;
    }

    static checkGroupPermission(url) {
        var result = false;
        var user = this.getGroupUser();
        var urlArray = url.split("/");
        if (user) {
            if (urlArray[1] == 'group') {
                if (user.management) {
                    user.management.forEach(element => {
                        if (result) return;
                        if (element.route) {
                            if (url.indexOf(element.route) >= 0) {
                                result = true;
                            }
                        }
                        element.children.forEach(e => {
                            if (url.indexOf(e.route) >= 0 && e.route != "") {
                                result = true;
                            }
                        });
                    });
                }
            }
        }
        return result;
    }

    static checkOwner() {
        try {
            let user = this.getUser();
            return user.owner == 'Y';
        } catch (error) {
            return false;
        }
    }

    static getDefaultFunction(url) {
        var result = '';
        var user = this.getUser();
        var urlArray = url.split("/");
        try {
            if (user) {
                if (urlArray[1] == 'management') {
                    if (user.management) {
                        user.management.forEach(element => {
                            if (result) return;
                            if (element.route) {
                                if (url.indexOf(element.route) >= 0) {
                                    result = '/management/' + element.route;
                                }
                            }
                            element.children.forEach(e => {
                                if (url.indexOf(e.route) >= 0 && e.route != "") {
                                    result = '/management/' + e.route;
                                }
                            });
                        });
                        if (result == '') {
                            result = '/management/' + user.management[0].children[0].route;
                        }
                    }
                }
            }
        } catch (error) {
            this.removeUser();
        }
        return result;
    }

    static getGroupDefaultFunction(url) {
        var result = '';
        var user = this.getGroupUser();
        var urlArray = url.split("/");
        try {
            if (user) {
                if (urlArray[1] == 'group') {
                    if (user.management) {
                        user.management.forEach(element => {
                            if (result) return;
                            if (element.route) {
                                if (url.indexOf(element.route) >= 0) {
                                    result = '/group/' + element.route;
                                }
                            }
                            element.children.forEach(e => {
                                if (url.indexOf(e.route) >= 0 && e.route != "") {
                                    result = '/group/' + e.route;
                                }
                            });
                        });
                        if (result == '') {
                            result = '/group/' + user.management[0].children[0].route;
                        }
                    }
                }
            }
        } catch (error) {
            this.removeGroupUser();
        }
        return result;
    }

    static getFunction(url, lastBread = '') {
        var result = [];
        var user = this.getUser();
        var urlArray = url.split("/");
        if (user) {
            if (urlArray[1] == 'management') {
                if (user.management) {
                    user.management.forEach(element => {
                        if (result.length > 0) return;
                        let str1 = element.route + "\/";
                        var re = new RegExp(str1, "g");
                        if (element.route) {
                            if (url.match(re) !== null) {
                                result.push(element);
                            }
                        }
                        element.children.forEach(e => {
                            str1 = e.route + "$";
                            re = new RegExp(str1, "g");
                            if (url.match(re) !== null && e.route != "") {
                                e.route = "/management/" + e.route;
                                result.push(e);
                            }
                        });
                    });
                }
                if (lastBread != '') {
                    result.push({ displayName: lastBread });
                }
            }
        }
        return result;
    }

    static getGroupFunction(url, lastBread = '') {
        var result = [];
        var user = this.getGroupUser();
        var urlArray = url.split("/");
        if (user) {
            if (urlArray[1] == 'group') {
                if (user.management) {
                    user.management.forEach(element => {
                        if (result.length > 0) return;
                        let str1 = element.route + "\/";
                        var re = new RegExp(str1, "g");
                        if (element.route) {
                            if (url.match(re) !== null) {
                                result.push(element);
                            }
                        }
                        element.children.forEach(e => {
                            str1 = e.route + "$";
                            re = new RegExp(str1, "g");
                            if (url.match(re) !== null && e.route != "") {
                                e.route = "/management/" + e.route;
                                result.push(e);
                            }
                        });
                    });
                }
                if (lastBread != '') {
                    result.push({ displayName: lastBread });
                }
            }
        }
        return result;
    }

    static getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    static getGroupUser() {
        return JSON.parse(localStorage.getItem('guser'));
    }

    static updateUserData(data) {
        let user = this.getUser();
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key) && Object.prototype.hasOwnProperty.call(user, key)) {
                const value = data[key];
                user[key] = value;
            }
        }
        localStorage.setItem('user', JSON.stringify(user));
    }

    static getUserName() {
        try {
            let user = this.getUser();
            return user.usrnam;
        } catch (error) {
            return '';
        }
    }

    static getUserId() {
        try {
            let user = this.getUser();
            return user.usrsid;
        } catch (error) {
            return '';
        }
    }

    static getPurchasePermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.purchase;
        } catch (error) {
            return false;
        }
    }

    static getManagementWarehousePermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.management_warehouse;
        } catch (error) {
            return false;
        }
    }

    static getManagementInventoryPermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.inventory;
        } catch (error) {
            return false;
        }
    }

    static getManagementMonthIncomePermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.income;
        } catch (error) {
            return false;
        }
    }

    static getCheckoutPermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.checkout;
        } catch (error) {
            return false;
        }
    }

    static getCheckoutEditPermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.checkout_edit;
        } catch (error) {
            return false;
        }
    }

    static getCheckoutDeletePermission() {
        try {
            let user = this.getUser();
            return user.owner == 'Y' || user.extra_permission.checkout_delete;
        } catch (error) {
            return false;
        }
    }

    static getReservationPermission() {
        try {
            let user = this.getUser();
            const appointment = user.management.find(x => x.route == 'appointment');
            let permission = false;
            if (appointment) {
                const subAppointment = appointment.children.filter(x => x.route == 'appointment/reservation' || x.route == 'appointment/scheduler');
                permission = subAppointment.length > 0;
            }
            return user.owner == 'Y' || permission;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    static getReservationDeletePermission() {
        try {
            let user = this.getUser();
            return user.extra_permission.delete_reservation;
        } catch (error) {
            return false;
        }
    }

    static getMemberPermission() {
        try {
            let user = this.getUser();
            const appointment = user.management.find(x => x.route == 'crm');
            let permission = false;
            if (appointment) {
                const subAppointment = appointment.children.filter(x => x.route == 'crm/member');
                permission = subAppointment.length > 0;
            }
            return user.owner == 'Y' || permission;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    static getBlockPermission() {
        try {
            let user = this.getUser();
            const appointment = user.management.find(x => x.route == 'crm');
            let permission = false;
            if (appointment) {
                const subAppointment = appointment.children.filter(x => x.route == 'crm/block' || x.route == 'appointment/scheduler');
                permission = subAppointment.length > 0;
            }
            return user.owner == 'Y' || permission;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    static getSalaryPermission() {
        try {
            let user = this.getUser();
            const finance = user.management.find(x => x.route == 'finance');
            let permission = false;
            if (finance) {
                const salary = finance.children.filter(x => x.route == 'finance/salary');
                permission = salary.length > 0;
            }
            return user.owner == 'Y' || permission;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    static getBroadcastPermission() {
        try {
            let user = this.getUser();
            const appointment = user.management.find(x => x.route == 'crm');
            let permission = false;
            if (appointment) {
                const push = appointment.children.filter(x => x.route == 'crm/pushmessage');
                permission = push.length > 0;
            }
            return user.owner == 'Y' || permission;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    static getMemberEditPermission(usrsid, isowner, mbedit, devpid) {
        try {
            if (isowner) return true;
            let result = false;
            switch (mbedit) {
                case MEMBER_EDIT_PERMISSION.NOT_ALLOW:
                    result = false;
                    break;
                case MEMBER_EDIT_PERMISSION.ALL_MEMBER:
                    result = true;
                    break;
                case MEMBER_EDIT_PERMISSION.ALL_MEMBER_REMARK:
                    result = true;
                    break;
                case MEMBER_EDIT_PERMISSION.SELF_MEMBER:
                    result = usrsid == devpid ? true : false;
                    break;
                case MEMBER_EDIT_PERMISSION.SELF_MEMBER_REMARK:
                    result = usrsid == devpid ? true : false;
                    break;
                case MEMBER_EDIT_PERMISSION.SELF_AND_OTHER_REMARK:
                    result = true;
                    break;
            }
            return result;
        } catch (error) {
            return false;
        }
    }

    static getOrderTimePermission() {
        let user = this.getUser();
        if (user.owner == 'Y') return true;
        return user.edtrtm === 1;
    }

    static getOnlineMallEditPermission() {
        try {
            let user = this.getUser();
            const finance = user.management.find(x => x.route == 'mall');
            let permission = false;
            if (finance) {
                permission = true;
            }
            return user.owner == 'Y' && permission;
        } catch (error) {
            return false;
        }
    }

    static getOnlineMallPermission() {
        try {
            let user = this.getUser();
            const finance = user.management.find(x => x.route == 'mall');
            let permission = false;
            if (finance) {
                permission = true;
            }
            return permission;
        } catch (error) {
            return false;
        }
    }

    static getMemberEditColumn(devpid) {
        try {
            let column = '';
            let user = this.getUser();
            if (user.owner == 'Y') return 'all';
            switch (user.mbedit) {
                case MEMBER_EDIT_PERMISSION.NOT_ALLOW:
                    column = '';
                    break;
                case MEMBER_EDIT_PERMISSION.ALL_MEMBER:
                    column = 'all';
                    break;
                case MEMBER_EDIT_PERMISSION.ALL_MEMBER_REMARK:
                    column = 'remark';
                    break;
                case MEMBER_EDIT_PERMISSION.SELF_MEMBER:
                    column = user.usrsid == devpid ? 'all' : '';
                    break;
                case MEMBER_EDIT_PERMISSION.SELF_MEMBER_REMARK:
                    column = user.usrsid == devpid ? 'remark' : '';
                    break;
                case MEMBER_EDIT_PERMISSION.SELF_AND_OTHER_REMARK:
                    column = user.usrsid == devpid ? 'all' : 'remark';
                    break;
            }
            return column;
        } catch (error) {
            return '';
        }
    }

    static getCustName() {
        let user = this.getUser();
        try {
            return user.cstnam;
        } catch (error) {
            return '';
        }
    }

    static getToken() {
        return localStorage.getItem("token");
    }

    static getGroupToken() {
        return localStorage.getItem("gtk");
    }

    static removeUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    static removeGroupUser() {
        localStorage.removeItem('guser');
        localStorage.removeItem('gtk');
    }

    static setStore(storid) {
        localStorage.setItem("store", storid);
    }

    static getStoreId() {
        try {
            let user = this.getUser();
            let store = user.management_store;
            return store.map(x => x.storid);
        } catch (error) {
            return [];
        }
    }

    static isSuperAdmin() {
        try {
            let user = this.getUser();
            return user.owner == "Y";
        } catch (error) {
            return false;
        }
    }

    static getStoreDetail(storid) {
        let store;
        var info = this.getUser();
        store = info.store.find(s => s.storid == storid);
        return store;
    }

    static getNotPayCount() {
        try {
            let user = this.getUser();
            return user.customer.notpay_count;
        } catch (error) {
            return 0;
        }
    }

    static checkServiceTime() {
        let res = {
            time: '',
            type: ''
        };
        try {
            res.time = this.getUser().customer.endtime;;
            let now = moment();
            const diffDays = moment(res.time).diff(now, 'days');
            if (diffDays < 0) {
                res.type = 'over';
            } else if (diffDays >= 0 && diffDays <= 30) {
                res.type = 'aboutto'
            }
        } catch (error) {
        }
        return res;
    }

    static getWarehouseEnable() {
        try {
            let user = this.getUser();
            return (user.customer.enable_warehouse == 1) && (user.owner == 'Y' || user.extra_permission.management_warehouse);
        } catch (error) {
            return false;
        }
    }

    static getTransferOthersEnable() {
        try {
            let user = this.getUser();
            return user.customer.transfer_stock == 1;
        } catch (error) {
            return false;
        }
    }

    static jsonParse(str) {
        try {
            return JSON.parse(str);
        }
        catch (e) {
            console.log(e);
            // Return a default object, or null based on use case.
            return {};
        }
    }

    static getHolidayName(type, doname = '') {
        if(type !== 'customize') {
            return HOLIDAY_TYPE[type];
        } else {
            return doname;
        }
    }

    static getReservationStatus(rsvtyp, usenot, memcel, aprove) {
        let status = { name: '', colorType: 'break' };
        if (rsvtyp == 1) {
            if (usenot == 'Y') {
                if (aprove == 'Y') {
                    status.colorType = 'reservation';
                    status.name = '已接受';
                    if (memcel == 'P') {
                        status.name += "(已確認)";
                    }
                    if (memcel == 'A') {
                        status.colorType = 'arrive';
                        status.name += "(已到店)";
                    }
                } else if (aprove == 'N') {
                    status.colorType = 'wait';
                    status.name += "待確認";
                }
            } else {
                if (memcel == 'NS') {
                    status.colorType = 'noshow';
                    status.name = '未到店';
                } else if (aprove == 'N' && memcel == 'N') {
                    status.colorType = 'refusecancel';
                    status.name = '已拒絕';
                } else if (aprove == 'Y' && memcel != 'Y') {
                    status.colorType = 'delete';
                    status.name = '已刪除';
                } else {
                    status.colorType = 'refusecancel';
                    status.name = '已取消';
                }
            }
        } else {
            status.colorType = 'checkout';
            status.name = '已開單';
        }
        return status;
    }

    static getReservationSource(source) {
        let sourceName = '';
        switch (source) {
            case 'line':
                sourceName = 'LINE';
                break;
            case 'google':
                sourceName = 'Google';
                break;
            case 'fb':
                sourceName = 'Facebook';
                break;
            case 'phone':
            case 'pc':
            case 'web2':
            case 'pad':
                sourceName = '工作人員預約';
                break;
        }
        return sourceName;
    }

    static getPaymentOptions(type = 'normal') {
        const p = PAYMENT;
        let options = [];
        let filters = [];
        switch (type) {
            case 'card':
                filters = ['cash', 'give', 'free'];
                break;
            case 'tickets':
                filters = ['cash', 'card', 'give', 'free'];
                break;
            case 'rpcash':
            case 'deposit':
                filters = ['cash', 'card'];
                break;
            case 'normal':
            default:
                filters = Object.keys(p);
                break;
        }
        for (const key in p) {
            if (Object.prototype.hasOwnProperty.call(p, key) && filters.indexOf(key) >= 0) {
                let element = p[key];
                if (key !== 'replace') {
                    element = element == '現金' ? "現場付款" : element;
                    options.push({
                        pkey: key,
                        name: element
                    });

                }
            }
        }
        return options;
    }

    static getCashpayOptions(display = [], payment = null) {
        const p = payment || CASHPAY_PAYMENTS_NAME;
        let options = [];
        if (display.length == 0) {
            display = DEFAULT_CASHPAY_DISPLAY;
        }
        if (payment) {
            for (const key in p) {
                if (Object.prototype.hasOwnProperty.call(p, key) && display.indexOf(key.toLowerCase()) >= 0) {
                    let element = p[key];
                    options.push({
                        pkey: key,
                        name: element.name
                    });
                }
            }
        } else {
            for (const key in p) {
                if (Object.prototype.hasOwnProperty.call(p, key) && display.indexOf(key.toLowerCase()) >= 0) {
                    let element = p[key];
                    options.push({
                        pkey: key,
                        name: element
                    });
                }
            }
        }

        return options;
    }

    /**
     * 取得偏好設定
     * @param key 取得localStorage的偏好設定值名稱
     * @param isBoolean 是否為bool型態的值
     * @param defaultValue 如果localStorage沒有設定，需要給的預設值
     * @return any
     */
    static getPreference(key, isBoolean = false, defaultValue: any = '') {
        let preferences: any;
        const value = localStorage.getItem(key);
        if (isBoolean) {
            preferences = value ? (value === 'yes' ? true : false) : defaultValue;
        } else {
            preferences = value ? value : defaultValue;
        }
        return preferences;
    }

    //中文數字單位
    static addWan(num, value, mutiple, decimalDigit) {
        var digit = this.getDigit(num);
        if (digit > 3) {
            var remainder = digit % 8;
            if (remainder >= 5) { // ‘十万’、‘百万’、‘千万’显示为‘万’
                remainder = 4;
            }
            return Math.round(value / Math.pow(10, remainder + mutiple - decimalDigit)) / Math.pow(10, decimalDigit) + '萬';
        } else {
            return Math.round(value / Math.pow(10, mutiple - decimalDigit)) / Math.pow(10, decimalDigit);
        }
    }
    static getDigit(num) {
        var digit = -1;
        while (num >= 1) {
            digit++;
            num = num / 10;
        }
        return digit;
    }
    static addChineseUnit(value, decimalDigit) {
        decimalDigit = decimalDigit == null ? 2 : decimalDigit;
        var num = Math.floor(value);
        var digit = this.getDigit(num);
        var unit = [];
        if (digit > 3) {
            var multiple = Math.floor(digit / 8);
            if (multiple >= 1) {
                var tmp = Math.round(num / Math.pow(10, 8 * multiple));
                unit.push(this.addWan(tmp, value, 8 * multiple, decimalDigit));
                for (var i = 0; i < multiple; i++) {
                    unit.push('億');
                }
                return unit.join('');
            } else {
                return this.addWan(num, value, 0, decimalDigit);
            }
        } else {
            return value;
        }
    }

    static transformStaffOptions(staffData) {
        let staffOptions = [];
        for (let index = 0; index < staffData.length; index++) {
            const position = staffData[index];
            for (const key in position) {
                if (Object.prototype.hasOwnProperty.call(position, key)) {
                    const staffArr = position[key];
                    staffOptions = staffOptions.concat(staffArr);
                }
            }
        }
        return staffOptions;
    }

    //陣列排序
    static compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    static getUniqueID() {
        return Date.now() + ((Math.random() * 100000).toFixed());
    }

    static workHourTransform(hour) {
        let text = "0";
        let totalMins = hour * 60;
        let hours = Math.floor(totalMins / 60);
        let mins = totalMins % 60;
        if (hours > 0 || mins > 0) {
            text = (hours > 0 ? hours + "小時" : '') + (mins > 0 ? mins + "分鐘" : "");
        }
        return text;
    }

    static getSystemType() {
        const user = this.getUser();
        return user.system ?? 'saydou';
    }
}
