import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { Helpers } from "../helpers";

@Injectable()
export class NewebpayGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(){
        let can = false;
		if (Helpers.checkLogin() && Helpers.isSuperAdmin()) {
            can = true;
        }
        if(!can) {
            this.router.navigate(['/denied']);
        }
        return can;
    }
}
