// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    url: '',
    recaptchaKey: '6Lds-7kaAAAAAKWuyBQN-a91O7qgxSjiCXvw7-Kj',
    giftImageUrl: 'https://storage.googleapis.com/saydou_public_other/gift_img/',
    goodImageUrl: 'https://storage.googleapis.com/saydou_public_other/gods_img/',
    defaultImgUrl: 'https://storage.googleapis.com/saydou_public_other/default_img/godsImage.png',
    defaultOrderImgUrl: 'https://storage.googleapis.com/saydou_public_other/default_img/defaultImage.png',
    staffImageUrl: 'https://storage.googleapis.com/saydou_public_other/user_img/',
    defaultStaffImgUrl: 'https://storage.googleapis.com/saydou_public_other/default_img/userImage.png',
    storeImageUrl: 'https://storage.googleapis.com/saydou_public_other/store_img/',
    defaultStoreImgUrl: 'https://storage.googleapis.com/saydou_public_other/default_img/storeImage.png',
    memberImageUrl: 'https://storage.googleapis.com/saydou_public_im_other/memb_img/',
    defaultMemberImgUrl: 'https://storage.googleapis.com/saydou_public_other/default_img/userImage.png',
    customerImgageUrl: 'https://storage.googleapis.com/saydou_public_other/cust_img/',
    mallImageUrl: 'https://storage.googleapis.com/saydou_public_other/mall_gods_img/',
    orderImageUrl: 'https://storage.googleapis.com/saydou_public_im_other/ordc_model_img/',
    memberCardImageUrl: 'https://storage.googleapis.com/saydou_public_other/member_card/',
    petImageUrl: 'https://storage.googleapis.com/saydou_public_im_other/pet_img/',
    production: true,
    lineClientId: '1656021863',
    lineDevClientId: '1655854965',
    reservationConfirmMessage: '親愛的客人 {{member_name}} 您好，服務人員 {{designer_name}} 已經接受您於 {{reservation_time}} 這個時間的預約，再請您於預約的時間光臨 {{store_name}}，謝謝。',
    reservationRefuseMessage: '親愛的 {{member_name}} 您好，服務人員 {{designer_name}} 因有其他工作暫時無法接受您的預約，再請您改約其他時間，謝謝!\n門店: {{store_name}}\n預約時間: {{reservation_time}}',
    backend: {
        baseURL: "https://saywebdatafeed.saydou.com" //正式
    },
    firebase: {
        apiKey: "AIzaSyDrEP1xgjWxFIUlbhAFPihDxENX7Ij5gwY",
        authDomain: "saydou-line-marketplace.firebaseapp.com",
        projectId: "saydou-line-marketplace",
        storageBucket: "saydou-line-marketplace.appspot.com",
        messagingSenderId: "34609610065",
        appId: "1:34609610065:web:c6524e3bb3378d4adf2770",
        measurementId: "G-VTE0Y3LKDK"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
