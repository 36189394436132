import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Helpers } from "../helpers";

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot){
		if (Helpers.checkLogin()) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
