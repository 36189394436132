import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class BindingService {
    baseUrl: string = environment.backend.baseURL;

    constructor(private http: HttpClient) { }

    tempData(body?) {
        let param = { params: body };
        let header = this.ignoreLoadingHesders();
        param = { ...param, ...header };
        return this.http.get<any>(`${this.baseUrl}/api/marketplace/tempData`, param);
    }

    tempLiteData(body?) {
        let param = { params: body };
        let header = this.ignoreLoadingHesders();
        param = { ...param, ...header };
        return this.http.get<any>(`${this.baseUrl}/api/marketplace/lite/tempData`, param);
    }

    customerData(body?) {
        let param = { params: body };
        param = { ...param};
        return this.http.get<any>(`${this.baseUrl}/api/marketplace/customerData`, param);
    }

    bindingOfficialAccount(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/marketplace/bindingOfficialAccount`, body);
    }
    bindingLiteOfficialAccount(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/marketplace/lite/bindingOfficialAccount`, body);
    }
    rebindOfficialAccount(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/marketplace/lite/rebindingOfficialAccount`, body);
    }
    buildLiteAccount(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/marketplace/lite/buildLiteAccount`, body);
    }
    setLiteRichmenu(body?) {
        return this.http.post<any>(`${this.baseUrl}/api/marketplace/lite/richmenu`, body);
    }
    private ignoreLoadingHesders() {
        return { headers: { 'ignoreloading': '1' } };
    }
}
