import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helpers';

@Component({
    selector: 'app-denied',
    templateUrl: './denied.component.html',
    styleUrls: ['./denied.component.css']
})
export class DeniedComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    logout() {
        Helpers.removeUser();
        Helpers.removeGroupUser();
        this.router.navigate(['/login']);
    }
}
