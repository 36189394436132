import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { LoadingScreenService } from 'src/app/services/common/loading-screen.service';
import { Helpers } from 'src/app/helpers';
@Injectable({
	providedIn: 'root'
})
export class SaydouPreInterceptor implements HttpInterceptor {
  constructor(private loadingScreenService: LoadingScreenService) { }
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('ignoreloading') == null) {
            this.loadingScreenService.startLoading();
        } else {
            // req = req.clone({ headers: req.headers.delete('ignoreloading')});
        }
        // const newRequest = req.clone({ setHeaders: { "Content-Type": 'application/x-www-form-urlencoded' } });
        if (req.headers.get('ignorepermission') == null) {
            let newRequest;
            if(req.headers.get('group') == null) {
                newRequest = req.clone({ setHeaders: { Authorization: 'Bearer ' + Helpers.getToken() } });
            } else {
                newRequest = req.clone({ setHeaders: { Authorization: 'Bearer ' + Helpers.getGroupToken() } });
            }
            return next.handle(newRequest);
        } else {
            return next.handle(req);
        }
	}
}
