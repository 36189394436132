import { Component, Inject, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoadingScreenService } from './services/common/loading-screen.service';
import { CommonService } from './services/common/common.service';
import { DOCUMENT } from '@angular/common';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'sayWeb';
    base = {
        "theme": "saydou-theme",
        "primaryColor": "#AF4585",
        "logoPngUrl": "../../../assets/images/saydou_logo.png",
        "backgroundUrl": "../../../assets/images/login_background.jpg"
    };
    constructor(private _router: Router, private loadingScreenService: LoadingScreenService,
        private dialogRef: MatDialog,
        private cs: CommonService,
        private titleService: Title,
        iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
        private renderer: Renderer2, @Inject(DOCUMENT) private document: Document
    ) {
        iconRegistry.registerFontClassAlias('saydou_icon', 'SayDou_iconfont');
        iconRegistry.registerFontClassAlias('petto_icon', 'Petto_iconfont');
    }

    ngOnInit() {
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                this.dialogRef.closeAll();
                // this.loadingScreenService.startLoading();
            }
            if (route instanceof NavigationEnd) {
                // this.loadingScreenService.stopLoading();
            }
        });
        const domain = window.location.hostname;
        if (domain === 'm.saydou.com' || domain == 'localhost.saydou' || domain === 'm.saydou.dev') {
            this.base.theme = "saydou-theme";
            this.base.primaryColor = "#AF4585";
            this.base.logoPngUrl = "../../../assets/images/saydou_logo.png";
            this.base.backgroundUrl = "../../../assets/images/login_background.jpg";
            this.titleService.setTitle("SayDou");
            this.setFavicon('favicon.ico');
        } else if (domain === 'm.saydou.pet' || domain == 'localhost.petto' || domain === 'pet.saydou.dev') {
            this.base.theme = "petto-theme";
            this.base.primaryColor = "#E98B2F";
            this.base.logoPngUrl = "../../../assets/images/petto_logo.png";
            this.base.backgroundUrl = "../../../assets/images/petto_background.png";
            this.titleService.setTitle("Petto");
            this.setFavicon('pfavicon.ico');
        } else {
            this.base.theme = "saydou-theme";
            this.base.primaryColor = "#AF4585";
            this.base.logoPngUrl = "../../../assets/images/saydou_logo.png";
            this.base.backgroundUrl = "../../../assets/images/login_background.jpg";
            this.titleService.setTitle("SayDou");
            this.setFavicon('favicon.ico');
        }
        this.cs.setHost(this.base);
    }

    setFavicon(faviconUrl: string) {
        const link: HTMLLinkElement = this.renderer.createElement('link');
        link.rel = 'icon';
        link.href = faviconUrl;
        link.type = 'image/x-icon';
        const head = this.document.getElementsByTagName('head')[0];
        const existingFavicon = head.querySelector('link[rel*="icon"]');

        if (existingFavicon) {
            this.renderer.removeChild(head, existingFavicon);
        }

        this.renderer.appendChild(head, link);
    }
}
