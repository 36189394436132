<div style="height:500px; overflow: auto;">
    <p><span style="font-family:標楷體; font-weight:bold">集客小舖會員服務條款</span></p>
    <p><span style="font-family:標楷體; -aw-import:ignore">&#xa0;</span></p>
    <p><span
            style="font-family:標楷體">SayDou集客小舖網路行動購物平台（以下稱「本平台」）是由安騰創新生活科技有限公司（以下稱「本公司」）所經營之B2B2C網站，提供會員向本平台網路開店商家（以下稱「美業商家」）進行商品購買或服務。任何使用者使用本平台服務時，請先詳細閱讀本服務條款，並請確實遵守；若您無法遵守本服務條款內容、或對於本服務條款內容全部或部分不同意時，提醒您請勿使用本平台服務。</span>
    </p>
    <p><span style="font-family:標楷體; font-weight:bold">第1條：服務說明</span></p>
    <p><span style="font-family:標楷體">本平台為B2B2C網站，提供本平台開店服務，會員則可使用本平台相關功能並向美業商家購買其等所提供的商品或服務。會員購買商品或服務時，並非向本公司購買，而是向美業商家購買，美業商家之名稱載明於本平台美業商家頁面。
            本平台美業商家之商品或服務配送地區，依據個別商品或服務之資訊頁面記載，如屬配送地區以外之消費者，請勿訂購，以避免產生無法順利送達及運費計算之困擾。</span></p>
    <p><span style="font-family:標楷體; font-weight:bold">第2條：本平台會員</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">您同意於申請使用本平台服務時，同時加入成為本平台會員，您所填寫之個人資料將由本公司依個人資料保護法規定予以保護。您同意本公司得以電子郵件、簡訊或其他方式提供您本平台各項服務及相關優惠訊息。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">您所申請之帳號、密碼於本平台開通後，將得於本公司導入本平台會員登入機制之各項服務中登入使用。</span>
        </li>
    </ul>
    <p><span style="font-family:標楷體; font-weight:bold">第3條：交易注意事項</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">您加入本平台會員後，即可透過本平台向在本平台上開店的美業商家，選購您所喜歡的商品或服務，亦即，您是向美業商家，而非本公司購買商品或服務。美業商家於本平台銷售頁面所載商品照片或其他說明資訊，可能因為個別商品特性有所差異，惟本公司將要求美業商家遵守消費者保護法、公平交易法等，如您所收悉商品與美業商家刊登之商品資訊不相符，您可透過本平台向美業商家申請或依法解約退貨，本公司將要求美業商家儘速處理，以保障您的權益。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">美業商家於本平台銷售其商品或服務，運費及優惠免運費之標準，將</span><span
                style="font-family:標楷體; font-size:12pt; color:#ff0000">由美業商家</span><span
                style="font-family:標楷體; font-size:12pt">標示於商品或服務之頁面。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">為維護消費者權益，如美業商家原定出貨日期有延遲或當批到貨數量不足，美業商家將以電子郵件、簡訊或LINE訊息向您通知後延後出貨。若您在時程上無法配合或有其他因素致不需要該商品，您得透過本平台向美業商家辦理取消訂購，本平台將立即</span><span
                style="font-family:標楷體; font-size:12pt; color:#ff0000">依照退款辦法為您辦理</span><span
                style="font-family:標楷體; font-size:12pt">退款事宜。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">美業商家應於消費者訂立契約前，提供商品之種類、數量、價格及其他重要事項之確認機制，並應於契約成立後，確實履行契約。</span>
        </li>
    </ul>
    <p><span style="font-family:標楷體; font-weight:bold">第4條：消費者權益保護</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">本平台將要求美業商家依消費者保護法規定保障您的權益，若美業商家有違反消費者保護法規定之情形，您可透過本平台向本公司反應，本公司將立即進行調查，並就個案要求美業商家依法處理。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">若您透過本平台完成之交易，決定就當次交易商品取消訂購或依法解約請求退款時，您可透過登入本平台會員帳號後，辦理取消訂購或解約退款事宜。本平台將依您原先選擇之付款方式，協助美業商家辦理信用卡刷退或退款至您指定之銀行或郵局帳戶，然因申請退款所需提供資訊，悉依本平台合作之第三方金流為主，若您輸入之資訊不正確，將造成無法退款成功，敬請見諒。若因您取消部分商品或服務訂購致當次消費未符合免收運費之標準，美業商家將另行補收運費，並自退費款項中扣抵。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">您透過本平台向美業商家訂購商品或服務時，請務必確認符合所需才下單購買。若經美業商家向本公司反應特定帳戶有多次任意退換貨、無故取消帳單，或是其他顯不恰當等情形，為避免美業商家經營、作業上的困擾或損失，美業商家或本公司將得視情況拒絕交易，暫時或永久終止對您提供商品或服務銷售服務。</span>
        </li>
    </ul>
    <p><span style="font-family:標楷體; font-weight:bold">第5條：隱私權保護</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">除本服務條款另有約定者外，本公司及美業商家將依個人資料保護法及本平台隱私權政策提供您有關個人資料保護及利用。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">本公司要求美業商家不得將會員因交易所提供之個人資料為超出該交易服務範圍之利用，以確保會員個人資料權益。若會員發現本平台美業商家，將您使用本平台所提供的個人資料運用在非特定交易之服務，而有其他行銷、廣告或不當個資利用情形，請您透過本平台客服管道與本公司聯繫，本公司將立即調查並為適當處理。</span>
        </li>
    </ul>
    <p><span style="font-family:標楷體; font-weight:bold">第6條：責任限制</span></p>
    <p><span style="font-family:標楷體">如依法令或契約本公司須就您使用或無法使用本平台相關服務負任何賠償或違約責任時，您同意本公司所負賠償責任，以新台幣【2,000】元作為本公司賠償責任之上限。</span>
    </p>
    <p><span style="font-family:標楷體; font-weight:bold">第7條：其他</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">本服務條款除構成您與本公司就本平台相關服務使用的完整合意外，並同時作為您與在本平台開店之美業商家間，有關透過本平台交易之契約內容。本服務條款之解釋及履行，各方同意以中華民國法律為準據法。如因本服務條款產生任何爭議，除法律另有規定者外，各方同意以台灣桃園地方法院為第一審管轄法院。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">未經本公司同意授權，請勿將本平台全部或部分資料，以任何方式進行操作利用，若有違反相關法律之行為，將依法提出刑、民事訴訟。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">若您發現本平台上美業商家所提供之服務商品或圖片，有任何侵權情形，請與本公司反映，收到通報後本公司將立即轉知美業商家，並依美業商家之回報協助為適當之處理。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">本公司保留</span><span
                style="font-family:標楷體; font-size:12pt; color:#ff0000">隨時停止及</span><span
                style="font-family:標楷體; font-size:12pt">修改本平台服務條款之權利，並於修改後公布於本平台上，您同意自主閱讀。若該等修改涉及會員權益重大情形，將以e-mail或簡訊等方式進行通知。您於服務條款發布生效後仍繼續使用本平台相關服務，視為您同意修改內容；若您不同意修改的全部或部分內容，請您終止本平台會員之法律關係，並停止使用本平台相關服務。</span>
        </li>
    </ul>
    <p><span style="font-family:標楷體; font-weight:bold">隱私權政策</span></p>
    <p><span style="font-family:標楷體; -aw-import:ignore">&#xa0;</span></p>
    <p><span
            style="font-family:標楷體">本公司十分重視您的隱私權保護，將依個人資料保護法及本隱私權政策蒐集、處理及利用您的個人資料，並提供您對個人資料權利之行使與保護。另外提醒，歐盟會員身份不適用本公司目前所有服務，若您未告知是否具備歐盟會員國國民身份，本公司將認定您不具備歐盟國民身份，並會以此為基礎為您提供服務。若您不同意本隱私權政策之全部或部分，或具備歐盟會員身分者，請您停止使用本網站服務。</span>
    </p>
    <p><span style="font-family:標楷體; font-weight:bold">1. 本隱私權政策適用之範圍</span></p>
    <p><span style="font-family:標楷體">本隱私權政策適用於會員於本平台之使用及向美業商家購物所衍生之個人資料蒐集、處理及利用事宜。請於使用本平台服務前，確認您已審閱並同意本隱私權政策所列全部條款，若您不同意全部或部分者，請勿使用本平台服務。
            您可能經由本平台連結至非本公司或美業商家以外之第三人所經營之網站或服務，各該網站所為之個人資料蒐集係依其網站之隱私權政策規定處理，與本公司或美業商家無涉。</span></p>
    <p><span style="font-family:標楷體; font-weight:bold">2. 個人資料保護法應告知事項</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">蒐集機關名稱：安騰創新生活科技有限公司及美業商家（美業商家名稱列於商品頁面）。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">蒐集目的：提供本公司及本平台會員相關服務、美業商家與會員間有關電子商務等相關行銷、數據分析及資料訓練、契約、類似契約或其他法律關係事務、消費者、客戶管理與服務、網路購物及其他電子商務服務、廣告和商業行為管理業務、其他經營合於本公司營業登記項目或組織章程所定之業務。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">個人資料類別：識別類（姓名、地址、聯絡電話、電子郵件信箱）、特徵類（年齡、性別、出生年月日等）、消費資料、其他（往來電子郵件、網站留言、系統自動紀錄之軌跡資訊等），惟將以實際本公司取得之個人資料為限。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">個人資料利用期間：本平台會員有效期間及終止後六個月；若非會員則於該蒐集個人資料之目的消失後六個月。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span style="font-family:標楷體; font-size:12pt">個人資料利用地區：本公司執行業務及伺服器主機所在地。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">個人資料利用對象：本公司、各該交易之美業商家（美業商家無法取得非於該商家消費之資料）及電子商務相關協力廠商（例如：提供物流、金流或活動贈品、展示品之廠商）；如為本公司與其他廠商共同蒐集者，將於該共同蒐集之活動中載明。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">個人資料利用方式：依蒐集目的範圍及本隱私權政策進行利用。本公司限制美業商家於個別交易以外之情形向會員行銷，如有發現該等濫用個人資料之情事，請通知本公司。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">行使個人資料權利方式：依個人資料保護法第3條規定，您就您的個人資料享有查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除之權利。您可以透過寄送電子郵件至【support@saydou.com】（為避免電子郵件系統漏信或其他原因無法收悉，以本公司回覆收悉為準）或以書面郵寄至【桃園市龜山區文興路146號4樓之2
                安騰創新生活科技有限公司】方式行使上開權利，本公司將於收悉您的請求後儘速處理，並會代您將相關請求轉知美業商家一併處理。</span>
        </li>
        <li
            style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
            <span
                style="font-family:標楷體; font-size:12pt">個人資料選填說明：若於蒐集個人資料時，相關網頁或文件載明為選填者，僅為提供您使用本平台更佳體驗，不影響您使用本平台之權益。</span>
        </li>
    </ul>
    <p><span style="font-family:標楷體; font-weight:bold">3. 個人資料蒐集、處理及利用說明</span></p>
    <p><span style="font-family:標楷體">本公司可能透過LINE、Facebook或類似社群服務系統，於取得您的同意後，將部分本平台的資訊發布於您的社群活動資訊頁面，若您不同意該等訊息之發布，請您勿點選同意鍵，或於事後透過各該社群服務之會員機制移除該等資訊或拒絕本平台繼續發布相關訊息。若有任何問題，仍可與本公司聯絡，本公司將協助您確認、處理相關問題。
            若您所填寫之送貨地址、聯絡人、聯絡方式等非您本人之個人資料，您同意已取得各該當事人之同意提供予本公司，並已代表本公司就前開法定告知事項予以告知。除依法應提供予司法、檢調機關、相關主管機關，或與本公司協力廠商為執行相關活動必要範圍之利用外，本公司將不會任意將您的個人資料提供予第三人。當本公司或本平台全部或部分分割、獨立子公司經營、被其他第三者購併或收購資產，導致經營權轉換時，本公司會於事前將相關細節公告於本平台，且本公司或本平台所擁有之全部或部分使用者資訊亦可能在經營權轉換的狀況下移轉給第三人。惟限於與該經營權轉換服務相關之個人資料。若本公司或本平台部分營運移轉予第三人，您仍為本公司會員，若您不希望本公司後續利用您的個人資料，您可以依本隱私權政策向本公司行使權利。</span>
    </p>
    <p><span style="font-family:標楷體; font-weight:bold">4. Cookie技術</span></p>
    <p><span
            style="font-family:標楷體">為便於日後的辨識，當您使用本平台服務時，本公司可能會在您的電腦或手持式設備上設定與存取Cookie或其他類似技術。您可以透過設定您的個人電腦或手持式設備，決定是否允許Cookie或其他類似技術的使用，若您關閉Cookie或其他類似技術時，可能會造成您使用本平台服務時之不便利或部分功能限制。</span>
    </p>
    <p><span style="font-family:標楷體; font-weight:bold">5. 保密與安全性</span></p>
    <p><span style="font-family:標楷體">本公司及美業商家之員工，僅於其為您提供產品或服務之需求範圍內，對於您的個人資料得為有限之接觸。為了保護您的帳戶及個人資料的安全，請您不要任意將個人帳號、密碼提供予第三人或允許第三人以您的個人資料申請帳號、密碼，否則，相關責任由您自行負擔。若您的帳號、密碼有外洩之虞，請您立即更改密碼，或通知本公司暫停該帳號（本公司可能會要求核對您的個人資料）。
            網際網路並不是一個安全的資訊傳輸環境，請您在使用本平台時，避免將敏感的個人資料提供予他人或在網站上公開揭露。</span></p>
    <p><span style="font-family:標楷體; font-weight:bold">6. 未成年人保護</span></p>
    <p><span
            style="font-family:標楷體">本平台並非特別為未成年人/兒童設計，未成年人使用本平台時，若同意本平台蒐集、利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。</span>
    </p>
    <p><span style="font-family:標楷體; font-weight:bold">7. 隱私權政策之修改</span></p>
    <p><span
            style="font-family:標楷體">隱私權政策如經修改，本平台將以您所提供之電子郵件或行動電話通知您相關之重大變更，並於本平台公告。若您不同意該等變更或修改，請停止繼續使用本平台服務，並依本隱私權政策規定通知本公司及美業商家停止蒐集、處理及利用您的個人資料。</span>
    </p>
    <p><span style="font-family:標楷體; color:#ff0000; -aw-import:ignore">&#xa0;</span></p>
    <p><span
            style="font-family:標楷體; color:#ff0000">會員如已閱讀且同意接受前開約定內容，並同意以電子文件作為雙方合意的依據，請勾選下方的「我同意會員服務條款」按鈕。當按下前開按鈕後，表示會員瞭解正在進行且同意電子簽名，並同意本處之同意與文書簽名具有同等效力。</span>
    </p>
    <p><span style="font-family:標楷體; color:#ff0000">會員可於按下按鈕後七日內不附理由主張終止本協議，且不負擔任何損害賠償責任。但本公司與會員間之契約內容可能將因此受到影響。</span>
    </p>
</div>
