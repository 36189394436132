export const ORDER_ACCEPT_PAYMENTS = {
    SERVICES: ['cash', 'card', 'free', 'replace', 'give'],
    GOODS: ['cash', 'card', 'free', 'replace', 'give'],
    TICKETS: ['ticket'],
    ALL: ['cash', 'card', 'free', 'ticket', 'replace', 'give'],
    OPTIONS: ['cash', 'card', 'free', 'give'],
    NOTMEMBER: ['cash', 'free', 'give']
};

export const PAYMENT = {
    cash: "現金",
    card: "儲值金",
    free: "免費",
    replace: "優惠券",
    rpcash: "優惠券",
    ticket: "商品券",
    give: "贈送金",
};
export const PAYMENT_LIST = {
    1: {name: "現金"},
    2: {name: "儲值金"},
    3: { name: "贈送金" },
    4: { name: "優惠券}" },
    5: { name: "免費" },
};
export const STORE_ACCEPT_PAYMENTS = ['cash', 'give', 'free'];
export const STORE_BONUS_PAYMENTS = ['cash', 'give', 'free'];

export const TICKET_ACCEPT_PAYMENTS = ['cash', 'card', 'give', 'free'];
export const TICKET_BONUS_PAYMENTS = ['cash', 'card'];

export const RPCASH_ACCEPT_PAYMENTS = ['cash', 'card'];
export const LEVEL_ACCEPT_PAYMENTS = ['cash'];
export const RPCASH_BONUS_PAYMENTS = ['cash', 'card'];
export const DEPOSIT_ACCEPT_PAYMENTS = ['cash', 'card'];
export const DEPOSIT_BONUS_PAYMENTS = ['cash', 'card'];
export const DEFAULT_CASHPAY_DISPLAY = ['cashpay', 'creditcard', 'linepay', 'wechatpay', 'alipay', 'applepay', 'jkopay', 'googlepay', 'taiwanpay', 'cpay_1'];

export const CASHPAY_PAYMENTS = [
    'cashPay',
    'creditCard',
    'linePay',
    'wechatPay',
    'aliPay',
    'applePay',
    'jkoPay',
    'googlePay',
    'taiwanPay',
    'cpay_1',
    'cpay_2',
    'cpay_3',
    'cpay_4',
    'cpay_5',
];

export const CASHPAY_PAYMENTS_NAME = {
    cashPay:"現金",
    creditCard: "信用卡",
    linePay: "LINE Pay",
    wechatPay: "微信支付",
    aliPay: "支付寶",
    applePay: "Apple Pay",
    jkoPay: "街口支付",
    googlePay: "Google Pay",
    taiwanPay: "台灣Pay",
    cpay_1: "匯款",
    cpay_2: "",
    cpay_3: "",
    cpay_4: "",
    cpay_5: "",
};

export const CASHPAY_PAYMENTS_FEE = [
    'creditCard_fee',
    'linePay_fee',
    'wechatPay_fee',
    'aliPay_fee',
    'applePay_fee',
    'jkoPay_fee',
    'googlePay_fee',
    'taiwanPay_fee',
    'cpay_1_fee',
    'cpay_2_fee',
    'cpay_3_fee',
    'cpay_4_fee',
    'cpay_5_fee',
];

export const CASHPAY_PAYMENTS_TRANSFORM = {
    cashpay: 'cashPay',
    creditcard: 'creditCard',
    linepay: 'linePay',
    wechatpay: 'wechatPay',
    alipay: 'aliPay',
    applepay: 'applePay',
    jkopay: 'jkoPay',
    googlepay: 'googlePay',
    taiwanpay: 'taiwanPay',
    cpay_1: 'cpay_1',
    cpay_2: 'cpay_2',
    cpay_3: 'cpay_3',
    cpay_4: 'cpay_4',
    cpay_5: 'cpay_5',
};

