import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
    providedIn: 'root'
})
export class SaydouSnackBarService {
    constructor(private _snackBar: MatSnackBar) { }
    public showBar(message, action: string="關閉", type: string="success") {
        this[`_${type}Bar`](message, action);
    }

    private _successBar(message, action) {
        this._snackBar.open(message, action, { panelClass: ['success-snackbar', 'bar-button'] });
    }

    private _warningBar(message, action) {
        this._snackBar.open(message, action, { panelClass: ['warning-snackbar', 'bar-button'] });
    }

    private _errorBar(message, action) {
        this._snackBar.open(message, action, { panelClass: ['error-snackbar', 'bar-button'], duration: 25000 });
    }

    private _logBar(message, action) {
        this._snackBar.open(message, action, { panelClass: ['log-snackbar', 'bar-button'] });
    }

    private _infoBar(message, action) {
        this._snackBar.open(message, action, { panelClass: ['info-snackbar', 'bar-button'] });
    }

}
